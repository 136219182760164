@use 'sass:color';

@import 'scss/variables';

@import 'scss/mixins';

@import 'scss/fonts';

%container {
    @include responsive(xs) {
        width: 100%;
        max-width: 90vw;
    }

    width: clamp(22rem, 1.429rem + 26.786vw, 40rem);

    min-height: 30rem;

    background: $white;

    border-radius: 1.2rem;
    box-shadow: 0 0 24px -7px rgb(0 0 0 / 75%);
}

.container {
    @extend %container;
    align-self: stretch;
}

.containerWithoutContent {
    @extend %container;
    align-self: flex-start;
}

.header {
    @include flex(flex-start, center, row);
    gap: 1.5rem;
    padding: 1.5rem;

    border-bottom: 1px solid $border-gray-light;

    p {
        flex: 1;
        margin: 0;
        padding: 0;

        color: $gray-85;

        font: $paragraph-disp;
        font-size: 2rem;
        line-height: 2rem;
    }
}

.mainContainer {
    @include flex(flex-start, stretch, column);
    @include responsive(md) {
        padding: 1.5rem;
    }
    gap: 1.5rem;
    height: 29.5rem;
    min-height: 25rem;
    padding: 1.5rem;
}

.textLeft {
    text-align: left;
}

.textCenter {
    text-align: center;
}

.text {
    @include responsive(xs) {
        text-align: center;
    }
    margin: 0;
    padding: 0;

    color: $gray-85;

    font: $paragraph-greek;

    font-size: 1.6rem;
}

.smallSpinner {
    @include flex;
    flex: 1;

    text-align: center;
}

.contentBox {
    height: 100%;
    padding: 2rem;

    border: 1px solid $border-gray-light;
    border-radius: 1.2rem;
}

.link {
    color: $blue;
    font: $paragraph-greek;
    font-size: 1.6rem;

    background: none;

    border: none;

    cursor: pointer;

    transition: 0.1s;

    &:hover {
        color: color.adjust($color: $blue, $lightness: 10%);

        transform: translateY(-5%);
    }

    &:active {
        color: color.adjust($color: $blue, $lightness: -10%);

        transform: translateY(5%);
    }
}

.button {
    @include responsive(xs) {
        width: 70%;
    }
    align-self: center;
}
