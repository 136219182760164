@import 'scss/mixins';

@import 'scss/variables';

@import 'scss/fonts';

.container {
    @include flex(center, center, column);
}

.upperSection {
    @include flex(center, flex-start, row);
    gap: 1.3rem;
}

.body {
    @include responsive(xs) {
        white-space: normal;
    }
    color: $gray-85;
    font: $paragraph;
    white-space: pre-wrap;
    text-align: left;
}
