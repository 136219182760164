@import 'scss/fonts';

@import 'scss/mixins';

@import 'scss/variables';

.container {
    @include flex(center, center);
    width: 100vw;
    height: 100vh;

    background-color: $porcelain;
}

.spinnerContainer {
    @include flex(center, center, column);
    gap: 1rem;
    padding: 2rem 4rem;

    background-color: $white;

    border-radius: 1rem;
    box-shadow: 0 0 2.1rem 0 $transparent-button-black;
}

.ldSpinner {
    position: relative;

    display: inline-block;
    width: 8rem;
    height: 8rem;
}

.ldSpinner div {
    transform-origin: 4rem 4rem;

    animation: ld-spinner 1.2s linear infinite;

    &::after {
        position: absolute;
        top: 0.3rem;
        left: 3.7rem;

        display: block;
        width: 0.6rem;
        height: 1.8rem;

        background: $blue;

        border-radius: 20%;

        content: ' ';
    }

    &:nth-child(1) {
        transform: rotate(0deg);

        animation-delay: -1.1s;
    }

    &:nth-child(2) {
        transform: rotate(30deg);

        animation-delay: -1s;
    }

    &:nth-child(3) {
        transform: rotate(60deg);

        animation-delay: -0.9s;
    }

    &:nth-child(4) {
        transform: rotate(90deg);

        animation-delay: -0.8s;
    }

    &:nth-child(5) {
        transform: rotate(120deg);

        animation-delay: -0.7s;
    }

    &:nth-child(6) {
        transform: rotate(150deg);

        animation-delay: -0.6s;
    }

    &:nth-child(7) {
        transform: rotate(180deg);

        animation-delay: -0.5s;
    }

    &:nth-child(8) {
        transform: rotate(210deg);

        animation-delay: -0.4s;
    }

    &:nth-child(9) {
        transform: rotate(240deg);

        animation-delay: -0.3s;
    }

    &:nth-child(10) {
        transform: rotate(270deg);

        animation-delay: -0.2s;
    }

    &:nth-child(11) {
        transform: rotate(300deg);

        animation-delay: -0.1s;
    }

    &:nth-child(12) {
        transform: rotate(330deg);

        animation-delay: 0s;
    }
}

.text {
    font: $h4-desktop-l;
}

@keyframes ld-spinner {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
