@import 'scss/fonts';

@import 'scss/mixins';

.modalContainer {
    @include flex(space-between, center, row);
    @include responsive(md) {
        gap: 1.5rem;
        width: 100%;
    }
    @include responsive(xs) {
        @include flex(flex-start, flex-start, column-reverse);

        padding: 0 3rem;
    }
    gap: 3rem;
    width: 80vw;
    height: 100%;
}

.leftContainer {
    @include responsive(md) {
        height: clamp(10px, 44rem, 80rem);
    }
    @include responsive(xs) {
        width: 100%;
        height: 20rem;
        margin-bottom: 3rem;
    }
    width: 50%;
    height: 100%;
    object-fit: cover;
}

.rightContainer {
    @include responsive(md) {
        height: clamp(10px, 44rem, 80rem);
        padding: 5rem 3rem 3rem 0;

        white-space: unset;
    }
    @include responsive(xs) {
        width: 100%;
        height: auto;
        padding: 0;
        overflow-y: visible;
    }
    width: 50%;
    overflow-y: auto;

    white-space: pre-wrap;
}
