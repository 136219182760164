@import 'scss/fonts';

@import 'scss/variables';

* {
    scroll-behavior: auto !important;
}

html {
    font-size: 10px;
}

// This was originally set on the reboot.css that's installed on the app,
// and this is the ultimate culprit of the error icon bug that... for some reason
// ONLY HAPPENED IN THE VIRTUAL MACHINE. But setting it to an initial value,
// makes it work consistently in all platforms

svg {
    vertical-align: initial !important;
}

.css-8uhtka {
    font: $paragraph !important; // This !important is relevant since its a @veneer component
}

.StripeElement--focus {
    outline-color: $blue;
    border-color: $blue;
    border-radius: 1.2rem;

    transition: 0.2s;
}

.StripeElement--invalid {
    border-radius: 1.2rem;

    transition: 0.2s;
}

img {
    max-width: 100%;
    padding: 0;
}

@font-face {
    font-family: 'Forma DJR Text';
    src: url('../assets/fonts/formaDJRGreek/formadjrtextgreek-regular.woff')
        format('woff');
    src: url('../assets/fonts/formaDJRGreek/formadjrtextgreek-regular.woff2')
        format('woff2');
}

@font-face {
    font-family: 'Forma DJR Text Bold';
    src: url('../assets/fonts/formaDJRGreek/formadjrtext-bold.ttf')
        format('truetype');
}

@font-face {
    font-family: 'Forma DJR Display';
    src: url('../assets/fonts/formaDJRGreek/formadjrdisplaygreek-regular.woff')
        format('woff');
    src: url('../assets/fonts/formaDJRGreek/formadjrdisplaygreek-regular.woff2')
        format('woff2');
}

@font-face {
    font-family: 'Forma DJR Micro';
    src: url('../assets/fonts/formaDJRGreek/formadjrmicrogreek-regular.woff')
        format('woff');
    src: url('../assets/fonts/formaDJRGreek/formadjrmicrogreek-regular.woff2')
        format('woff2');
}

@font-face {
    font-family: 'Forma DJR Micro Medium';
    src: url('../assets/fonts/formaDJRGreek/formadjrmicrogreek-medium.woff')
        format('woff');
    src: url('../assets/fonts/formaDJRGreek/formadjrmicrogreek-medium.woff2')
        format('woff2');
}

@font-face {
    font-family: 'Forma DJR Micro Light';
    src: url('../assets/fonts/formaDJRGreek/formadjrdisplaygreek-light.woff')
        format('woff');
    src: url('../assets/fonts/formaDJRGreek/formadjrdisplaygreek-light.woff2')
        format('woff2');
}

@font-face {
    font-family: 'Forma DJR UI';
    src: url('../assets/fonts/FormaDJRUI.woff') format('woff');
}

@font-face {
    font-family: 'Forma DJR UI Italic';
    src: url('../assets/fonts/FormaDJRUI-Italic.woff') format('woff');
}

@media print {
    @page {
        margin: 5rem !important;
        padding: 0 !important;
    }
}

// Layout

$padding-inline: 2.8rem;
$content-max-width: 85vw;
$breakout-max-width: 85.5vw;
$content-size: min(100% - ($padding-inline * 2), $content-max-width);
$breakout-size: calc(($breakout-max-width - $content-max-width) / 2);

.content-grid {
    display: grid;
    grid-template-columns:
        [full-width-start] minmax($padding-inline, 1fr)
        [breakout-start] minmax(0, $breakout-size) [content-start] $content-size
        [content-end] minmax(0, $breakout-size) [breakout-end] minmax(
            $padding-inline,
            1fr
        )
        [full-width-end];
}

.content-grid > * {
    grid-column: content;
}

.content-grid > .breakout {
    grid-column: breakout;
}

.content-grid > .full-width {
    grid-column: full-width;
}
