@import 'scss/fonts';

@import 'scss/mixins';

.listItem {
    @include flex(flex-start, flex-start, row);
    @include responsive(xs) {
        gap: 1.6rem;
    }
    flex: 1;

    gap: 1rem;

    p {
        @include responsive(md) {
            white-space: normal;
        }

        margin: 0;
        padding: 0;

        font-size: 2rem;

        font-family: $ff-ui;
        line-height: 2.8rem;

        white-space: pre-wrap;
    }

    img {
        @include responsive(xs) {
            filter: unset;
        }
        width: 3rem;
        min-width: 3rem;
        height: 3rem;
        min-height: 3rem;

        filter: contrast(0%) brightness(200%);
    }
}
