@use 'sass:color';

@import 'scss/variables';

@import 'scss/fonts';

@import 'scss/mixins';

.container {
    @include flex(flex-start, flex-start, column);
    @include responsive(xs) {
        width: auto;
    }
    position: relative;

    width: 34rem;
}

.small {
    @include responsive(xs) {
        width: auto;
    }
    width: 100%;
}

.input {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    min-width: 14.4rem;
    margin: 0;
    padding: calc(0.8rem + 1.2rem - 0.1rem) calc(1.2rem - 0.1rem)
        calc(0.4rem - 0.1rem);

    color: $black;
    font: $paragraph;

    background-color: none;

    outline: 0.1rem solid $border-gray;

    border: none;
    border-radius: 1.2rem;
    opacity: 1;

    transition:
        background-color 150ms ease-out,
        outline-color 150ms linear;

    resize: none;
    will-change: background-color, outline-color;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        margin: 0;

        appearance: none;
    }

    &[type='number'] {
        appearance: textfield;
    }

    &:focus-visible {
        outline: 0.2rem solid $blue;
    }

    &:hover {
        background-color: $gray;

        outline-color: color.adjust($color: $gray, $lightness: -20%);
    }

    &:focus {
        outline-color: $blue;
    }
}

.info {
    font-size: 1.3rem;
}

.selected {
    position: absolute;
    z-index: 1;

    max-width: calc(100% - (0rem + 2.4rem));
    overflow: hidden;

    color: $placeholder-gray;
    font: $paragraph;
    font-size: 1.2rem;
    line-height: normal;

    white-space: nowrap;
    text-overflow: ellipsis;

    transform: translate(1.2rem, calc(0.8rem - 0.2rem));
    transform-origin: top left;

    transition:
        font-family 300ms linear,
        transform 150ms cubic-bezier(0, 0, 0.2, 1);

    pointer-events: none;
    will-change: font-family, transform;
}

.error {
    outline: 0.2rem solid $red-100;

    &:focus-visible {
        outline-color: 0.2rem solid $red-100;
    }

    &:hover {
        background-color: rgb(33 33 33 / 5%);

        outline-color: color.adjust($color: $red-100, $lightness: -10%);
    }
}
