@import 'scss/mixins';

@import 'scss/fonts';

@import 'scss/variables';

.container {
    @include flex(center, flex-start, column);
    @include responsive(xs) {
        @include flex(center, flex-start, column);
        gap: 3rem;
        min-width: 35.7rem;
        min-height: 120vmin;
        padding: 3.2rem 2rem;
    }
    gap: 5rem;

    min-width: 100vmin;
    min-height: 100vmin;

    padding: 9rem 4rem;

    color: $black;

    white-space: pre-line;

    background-color: $gray;
}

.accordionContainer {
    @include flex(center, stretch, column);
    gap: 1.2rem;
    width: 100%;
}

.title {
    @include responsive(md) {
        font-size: 5rem;
        line-height: 5rem;
    }
    @include responsive(xs) {
        font-size: 3rem;
        line-height: 3rem;
    }
    font: $title;
}

.button {
    @include responsive(xs) {
        align-self: stretch;
        margin-top: 3rem;
    }
    align-self: center;
}
