@import 'scss/mixins';

@import 'scss/variables';

@import 'scss/fonts';

.mainContainer {
    @include responsive(xs) {
        margin: 1rem;
    }
    position: absolute;
    z-index: 0;

    margin: 2rem 3rem;
    inset: 0;
}

.titleContainer {
    @include flex(flex-start, center, row);
    margin: 1rem;
}

.button {
    width: 2rem;
    margin-right: 1.5rem;
    padding: 0;

    background: none;

    border: none;
    rotate: 90deg;
}

.title {
    margin: 0;
    padding: 0;

    font: $title;
    font-size: 3rem;
}

.tableContainer {
    gap: 1px;
    width: 100%;
    height: fit-content;

    font: $paragraph;
    font-size: 1.2rem;
    word-wrap: break-word;

    table-layout: fixed;

    border-spacing: 1rem;

    td {
        padding: 1rem;

        vertical-align: middle;

        border: 1px solid $border-gray-light;
        border-right: none;

        &:first-child {
            border-left: none;
        }
    }
}
