@import 'scss/fonts';

@import 'scss/variables';

@import 'scss/mixins';

.contactContainer {
    @include responsive(md) {
        position: relative;

        margin-right: 1.4rem;

        border-radius: 1.6rem;
    }
    @include responsive(xs) {
        position: relative;
        bottom: unset;

        border-radius: 1.6rem;
    }
    position: absolute;
    bottom: 0;

    padding: 1.9rem;

    color: $black;
    text-align: center;

    background-color: $white;

    border-radius: 1.6rem 1.6rem 0 0;
    box-shadow: 0 0 24px -7px rgb(0 0 0 / 75%);
    @media screen and (height <= 1000px) {
        position: relative;

        border-radius: 1.6rem;
    }
}

.icon {
    margin-bottom: 2rem;
}

.contactBody {
    @include responsive(md) {
        white-space: normal;
    }
    color: $background-footer;
    font: $paragraph;
    line-height: 3rem;
    letter-spacing: 0.02em;
    white-space: pre-wrap;

    span {
        color: $blue;
        text-decoration: none !important;
    }
}
