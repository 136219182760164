@import 'scss/mixins';

@import 'scss/variables';

@import 'scss/fonts';

.icon {
    @include responsive(xs) {
        display: none;
    }

    width: 2rem;
    height: 2rem;
}

.specsTitleContainer {
    @include flex(flex-start, center, row);
    @include responsive(xs) {
        display: block !important;

        text-align: left;
    }

    gap: 2rem;
    margin-bottom: 2rem;
    margin-left: 1rem;
}

.specsTitleText {
    margin: 0;
    padding: 0;

    font: $paragraph;
    text-align: left;
}
