@import 'scss/mixins';

@import 'scss/fonts';

@import 'scss/variables';

.container {
    @include flex(flex-start, flex-start, row);
    gap: 2rem;
}

.stepContainer {
    width: 6rem;
}

.stepNumber {
    @include responsive(md) {
        line-height: 9rem;
    }
    @include responsive(xs) {
        width: 6rem;

        font-size: 6rem;
    }
    color: $black;
    font: $big-title;
    font-size: 10rem;
}

.title {
    @include responsive(md) {
        font-size: 3.5rem;
        line-height: 3.5rem;
    }
    @include responsive(xs) {
        font-size: 2.8rem;
        white-space: normal;
    }
    color: $black;
    font-size: 4.8rem;

    font-family: $ff-micro;
    line-height: 5rem;
}

.description {
    @include responsive(md) {
        font-size: 1.7rem;
        line-height: 2.3rem;
    }
    @include responsive(xs) {
        white-space: normal;
    }
    font-size: 2.4rem;

    font-family: $ff-micro;
    line-height: 3.2rem;
}
