@import 'scss/mixins';

@import 'scss/fonts';

@import 'scss/variables';

$icon-size: 4.2rem;

.container {
    @include flex(flex-start, center, column);
    @include responsive(xs) {
        @include flex(flex-start, flex-start, row);
        gap: 3.2rem;
        width: 100%;
        padding: 2.4rem 0.8rem;

        border-bottom: 1px solid $white;
    }
    gap: 2.4rem;

    &:last-child {
        border: none;
    }
}

.icon {
    width: $icon-size;
    min-width: $icon-size;
    height: $icon-size;
    min-height: $icon-size;

    filter: contrast(0%) brightness(200%);
}

.text {
    @include responsive(xs) {
        font-weight: 400;
        font-size: 1.4rem;
        white-space: normal;
        text-align: left;
    }
    font-size: 2rem;
    font-family: $ff-micro;

    white-space: pre-wrap;
}
