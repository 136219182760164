@import 'scss/mixins';

@import 'scss/variables';

@import 'scss/fonts';

.header {
    @include flex(space-between, center, row);
    @include responsive(xs) {
        @include flex(center, center, column);
        margin: 0;
    }
    margin-right: 5rem;
}

.title {
    font: $tas-subtitle;
}

.textContainer {
    margin: 3rem 0;

    font-size: 1.6rem;
    font-family: $ff-ui;
    line-height: 2.4rem;
    white-space: pre-wrap;
}

.footer {
    @include flex(space-between, space-between, row);
    @include responsive(xs) {
        @include flex(flex-start, flex-end, column);
        gap: 3.2rem;

        text-align: left;
    }
    gap: 3rem;
    width: 100%;
    margin-bottom: 1rem;
}

.printer {
    .footer,
    .download {
        visibility: hidden;
    }
}

.button {
    @include responsive(xs) {
        width: 100%;
    }
    width: 40%;
    padding: 1.5rem 0;

    font: $paragraph;
}
