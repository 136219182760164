@import 'scss/mixins';

@import 'scss/fonts';

@import 'scss/variables';

.container {
    @include flex(center, center, column);
    gap: 3rem;

    font: $ff-ui;

    text-align: center;
}

.header {
    h1 {
        @include font-size($fs-h3);
    }

    p {
        @include font-size($fs-body-small);
    }
}

.footer {
    @include flex(center, center, column);

    button {
        padding: 1.3rem 5rem;
    }
}

.link {
    color: $gray-85;
    text-decoration: underline;
}
