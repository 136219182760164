@use 'sass:color';

@import 'scss/variables';

@import 'scss/fonts';

@import 'scss/mixins';

.wrapper {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin: 0;
    padding: 0;
}

.wrapper input[type='checkbox'] {
    position: relative;

    width: 2.4rem;
    min-width: 2.4rem;
    height: 2.4rem;
    min-height: 2.4rem;

    outline: none;
    border: 0.15em solid #027aae;
    border-radius: 0.4rem;
    cursor: pointer;

    transition: 0.15s;

    appearance: none;

    &:hover {
        border-color: color.adjust($blue, $lightness: -5%);
    }

    &:checked {
        background-color: rgba(#027aae, 100%);
        background-image: url('/assets/check.svg');

        border-color: #027aae;

        &:hover {
            background-color: color.adjust($blue, $lightness: -5%);

            border-color: color.adjust($blue, $lightness: -5%);
        }
    }
}

.label {
    font: normal normal 400 1.6rem/2.5rem sans-serif;
}
