@import 'scss/fonts';

@import 'scss/mixins';

.button {
    @include flex(flex-start, center, row);
    @include responsive(xs) {
        width: 32.6rem;
        height: 8.5rem;
    }
    gap: $sp-200;
    margin: 0;
    padding: $sp-300 $sp-200 $sp-300 0;

    background: none;
    border: 0.1rem solid $dark-gray;
    border-radius: $sp-100;
    cursor: pointer;

    transition: 0.1s;

    img {
        width: 30%;
    }
    @supports (outline: 0.1rem solid $dark-gray) {
        outline: 0.1rem solid $dark-gray;
        border: none;
    }
}

.radioInput {
    display: none;

    visibility: hidden;

    &:checked + .button {
        border-color: $blue;
        border-width: 0.5rem;
        @supports (outline-color: $blue) and (outline-width: 0.5rem) {
            outline-width: 0.5rem;
            outline-color: $blue;
        }
    }
}

.titleContainer {
    @include flex(flex-start, flex-end, row);
    gap: $sp-100;
}

.title {
    @include font-size($fs-body);
    @include responsive(xs) {
        font-weight: $fw-bold;
    }
}

.model {
    @include font-size($fs-body-small);
    @include responsive(xs) {
        @include font-size($fs-body-small);
    }

    color: $transparent-black;
    font-weight: 300;
}

.body {
    @include font-size($fs-body-small);

    display: block;
    overflow: visible;

    font-family: $ff-ui;

    text-align: left;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;

    -webkit-line-clamp: 2;

    span {
        color: $blue;
    }
}
