@use 'sass:color';

@import 'scss/variables';

@import 'scss/fonts';

@import 'scss/mixins';

.container {
    min-width: 14.4rem;
    margin: 0;
    padding: 1.6rem;

    background-color: none;

    outline: 0.1rem solid $border-gray;

    border: none;
    border-radius: 1.2rem;
    opacity: 1;

    transition:
        background-color 150ms ease-out,
        outline-color 150ms linear;

    resize: none;
    will-change: background-color, outline-color;

    &:focus-visible {
        outline: 0.2rem solid $blue;
    }

    &:hover {
        background-color: rgb(33 33 33 / 5%);

        outline-color: $border-gray;
    }

    &:focus {
        outline-color: $blue;
    }
}

.halfSized {
    width: 50%;
}

.selected {
    outline: 0.2rem solid $blue;

    &:hover {
        outline-color: $blue;
    }
}

.error {
    outline: 0.2rem solid $red-100;

    &:focus-visible {
        outline-color: 0.2rem solid $red-100;
    }

    &:hover {
        background-color: rgb(33 33 33 / 5%);

        outline-color: color.adjust($color: $red-100, $lightness: -10%);
    }
}
