@import 'scss/fonts';

@import 'scss/variables';

@import 'scss/mixins';

.container {
    @include flex(space-around, center, row);
    @include responsive(xs) {
        @include flex(center, center, column);
        min-height: 80rem;
    }
    height: 100vmin;
    min-height: 60rem;

    overflow: hidden;
}

.mobileTitle {
    font: $h2-desktop-xs !important;
    text-align: center;
}

.image {
    @include background-image('/assets/hmp_default.webp');
    @include responsive(xs) {
        min-width: 25rem;
        min-height: 25rem;
    }
    min-width: 50rem;
    min-height: 50rem;
}

.pillTextContainer {
    display: inline-flex;
    gap: 1rem;
    margin-right: 1rem;
}

.rightSectionContainer {
    @include flex(center, flex-start, column);
    @include responsive(xs) {
        @include flex(center, center, column);
    }
}

.title {
    font: $h3-desktop-m !important;
    white-space: pre-wrap;
}

.titlePages {
    @include responsive(xs) {
        font: $modal-tiny-text !important;
        font-size: 1.1rem !important;
    }
    font: $comparison-table-feature-mobile !important;
}

.link {
    @include responsive(xs) {
        font: $caption-desktop-xs;
    }
    margin: 0;
    padding: 0;

    color: $blue;
    font: $body-desktop-l;

    background: none;

    border: none;
}

.buttonContainer {
    @include flex(center, flex-start, column);
    @include responsive(xs) {
        gap: 1.6rem;
        margin: 2.4rem 0 1.6rem;
    }
    gap: 1.35rem;
    margin: 3.1rem 0 1.7rem;
}

.pagesPerMonth {
    padding-right: 0.5rem;

    font-weight: 400;
}
