@import 'scss/mixins';

.zipContainer {
    @include flex(flex-start, flex-start, row);
    @include responsive(xs) {
        @include flex(flex-start, stretch, column);
        gap: 2rem;
        width: 100%;
    }
    gap: 1rem;
    width: 34rem;
}
