@import 'scss/mixins';

@import 'scss/variables';

@import 'scss/fonts';

.container {
    @include background-image(
        '/assets/print_responsibly_background.webp',
        contain,
        right
    );
    @include flex(center, flex-start, column);
    @include responsive(xs) {
        @include flex(flex-start, flex-start, column);
        @include background-image(
            '/assets/print_responsibly_background_mobile.webp',
            100% auto,
            bottom
        );
        min-width: 35.7rem;
        height: 80vh;
        min-height: 55rem;
        padding: 3.2rem 2rem;
    }
    gap: 3rem;

    min-width: 100vmin;
    min-height: 100vmin;

    padding: 9rem 4rem;

    color: $black;

    white-space: pre-line;

    background-color: $sixth-gray;
}

.title {
    @include responsive(md) {
        font-size: 5rem;
        line-height: 5rem;
    }
    @include responsive(xs) {
        font-size: 3rem;
        line-height: 3rem;
    }
    font: $title;
}

.infoContainer {
    @include flex(space-between, flex-start, row);
    gap: 2rem;
    padding: 1.6rem;

    background: $white;

    border-radius: 1rem;
}

.carbonNeutralBorder {
    border-left: 1.3rem solid $green;
}

.content {
    @include responsive(md) {
        font-size: 1.5rem;
        line-height: 2.3rem;
    }
    @include responsive(xs) {
        white-space: normal;
    }
    font: $paragraph;
    white-space: pre-wrap;
}

.image {
    width: 8.8rem;
    height: 10.7rem;
}
