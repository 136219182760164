@import 'scss/variables';

@import 'scss/fonts';

.serialNumberContainer {
    margin-top: 3.5rem;
    padding: 2rem;

    color: $white;

    background-color: $black;

    border-radius: 1.6rem;
}

.printerName {
    margin: 0;

    font: $paragraph-disp;

    font-weight: 400;
    font-size: 2.4rem;
    line-height: 4rem;
    letter-spacing: 2%;
}

.serialNumber {
    margin: 0;

    font: $paragraph-disp;

    font-weight: 400;
    font-size: 1.6rem;
    line-height: 3.2rem;
}
