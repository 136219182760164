@use 'sass:color';

@import 'scss/mixins';

@import 'scss/variables';

@import 'scss/fonts';

.container {
    $card-width: clamp(22rem, 1.429rem + 26.786vw, 40rem);
    $gap-space: clamp(1.5rem, -2.5rem + 5.208vw, 5rem);
    @include responsive(xs) {
        width: 100%;
        padding: 1.5rem;
    }

    width: calc($card-width * 3 + $gap-space * 2);
    margin: 3rem 0;
}

.tab {
    overflow: hidden;

    color: $blue;

    border-radius: 1.6rem;
    box-shadow: 0 0 24px -7px rgb(0 0 0 / 75%);

    transition: 0.2s;

    &Label {
        @include flex(space-between, center, row);
        @include responsive(md) {
            font-size: 1.5rem;
            line-height: 2.3rem;
        }
        padding: 1.2rem;

        font-size: 1.6rem;

        font-family: $ff-text;
        line-height: 2.4rem;

        background: $white;

        cursor: pointer;

        transition: 0.2s;

        &::after {
            min-width: 2rem;
            min-height: 2rem;

            text-align: center;

            transition: all 0.2s ease-in-out;

            content: url('/assets/chevron_down.svg');
        }

        &:hover {
            @include responsive(xs) {
                background: $white;
            }
        }
    }

    &Content {
        @include responsive(md) {
            font-size: 1.5rem;
            line-height: 2.3rem;
        }
        height: 0;
        padding: 0 1.2rem;

        color: color.adjust($dark-gray, $lightness: -10%);
        font-size: 1.6rem;
        font-family: $ff-text;
        line-height: 2.4rem;

        background: $white;
        opacity: 0;

        transition: 0.2s;

        ul {
            list-style-type: disc;
        }
    }
}

.input {
    position: absolute;
    z-index: -1;

    opacity: 0;
}

.input:checked {
    + .tabLabel {
        border-bottom: 1px solid $border-gray;

        &::after {
            transform: rotate(180deg);
        }
    }

    ~ .tabContent {
        @include flex(center, flex-start, row);
        @include responsive(xs) {
            @include flex(flex-start, flex-start, row);
            gap: 1rem;
            overflow-x: scroll;
        }

        gap: 3rem;

        height: 68rem;
        padding: 4.8rem 3rem;

        opacity: 1;
    }
}
