@import 'scss/mixins';

@import 'scss/fonts';

@import 'scss/variables';

.openDebugButton {
    position: absolute;
    top: 0;
    left: 50%;

    border-radius: 0 0 15px 15px;

    transform: translateX(-50%);

    &:active {
        transform: translateX(-50%);
    }
}
