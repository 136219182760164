@import 'scss/mixins';

@import 'scss/variables';

@import 'scss/fonts';

.linksContainer {
    text-transform: uppercase;

    list-style: upper-roman;
}
