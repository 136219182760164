@use 'sass:color';

@import 'scss/fonts';

@import 'scss/mixins';

$width: min(95%, 168rem);

.container {
    @include flex(flex-start, stretch, column);
    @include font-size($fs-body);

    gap: $sp-700;
    width: $width;

    margin: 4rem auto;

    p,
    h1,
    h2,
    h3,
    button {
        font-family: $ff-ui;
    }

    h1 {
        @include font-size($fs-h1);
    }

    h2 {
        @include font-size($fs-h2);
    }
}

.navButton {
    width: fit-content;

    background: none;
    border: none;

    inset: $sp-500 clamp($sp-300, -0.343rem + 3.571vw, $sp-600);
}

.printersImage {
    @include responsive(md) {
        max-width: 100%;
    }

    max-width: 49.6rem;
}

.textContainer {
    @include flex(center, center, column);
    gap: $sp-100;

    text-align: center;

    p {
        @include responsive(md) {
            width: 100%;
        }
        width: 95.2rem;
    }
}

.printerContainer {
    @include flex(space-between, center, row);
    @include responsive(md) {
        @include flex(center, center, column);
    }
    width: $width;
    margin-inline: auto;
}

.printerDescription {
    @include flex(flex-start, flex-start, column);

    p {
        margin: 0;
        padding: 0;
    }
}

.printerDescriptionTitle {
    @include flex(flex-start, flex-start, row);
    gap: $sp-100;
}

.link {
    margin: 0;
    margin-left: 0.5rem;
    padding: 0;

    color: $blue;

    background: none;
    border: none;

    transition: 0.1s;
}
