@use 'sass:color';

@import 'scss/fonts';

@import 'scss/variables';

@import 'scss/mixins';

.textContainer {
    @include responsive(xs) {
        font-size: 1.3rem;
    }
    font: $paragraph;
    font-size: min(1.6rem, 1.6vw);
}

.link {
    @include responsive(xs) {
        font-size: 1.3rem;
    }
    margin: 0;
    padding: 0;

    color: $blue;

    background: none;

    border: none;

    transition: 0.1s;

    &:hover {
        color: color.adjust($color: $blue, $lightness: 10%);

        transform: translateY(-5%);
    }

    &:active {
        color: color.adjust($color: $blue, $lightness: -10%);

        transform: translateY(5%);
    }
}
