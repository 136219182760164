@import 'scss/mixins';

@import 'scss/fonts';

@import 'scss/variables';

.container {
    min-height: 100dvh;

    color: #fff;

    font-family: $ff-micro;

    text-align: center;

    text-wrap: balance;

    background-color: #4e64ea;
}

.header {
    @include flex(center, center, column);

    margin-top: 9.6rem;

    h1 {
        @include responsive(xs) {
            padding: 0 2.4rem;

            font-size: calc(5rem / 2);
            line-height: unset;
        }

        font-size: 5rem;
        line-height: 9rem;
    }

    p {
        @include responsive(xs) {
            padding: 0 2.4rem;

            font-size: 1.5rem;
            line-height: unset;
        }

        margin-bottom: 9rem;

        font-size: 2.4rem;
        line-height: 3.4rem;
    }

    img {
        @include responsive(xs) {
            height: 15rem;
            object-fit: cover;
        }
    }
}

.main {
    @include flex(center, center, row);
    @include responsive(sm) {
        @include flex(center, center, column);
    }

    background-color: #aa5aed;

    h2 {
        color: #000;
        font-size: 2.4rem;
        font-family: $ff-micro;
    }

    p {
        color: #000;
        font-size: 1.8rem;
        font-family: $ff-micro;
        text-align: center;
    }

    button {
        margin-top: 2rem;
    }
}

.rightContainer {
    @include responsive(sm) {
        width: 100%;
    }

    width: 50%;
}

.leftContainer {
    @include responsive(sm) {
        width: 100%;
    }

    width: 50%;
}

.topSection {
    @include responsive(sm) {
        height: auto;
    }

    height: 30.4rem;
}

.bottomSection {
    @include responsive(sm) {
        height: auto;
    }

    height: 30.4rem;
}

.left {
    @include flex(center, center, column);
    @include responsive(sm) {
        padding: 3rem 0;
    }
    gap: 1rem;

    background-color: #c7ddfc;
}

.right {
    @include flex(center, center, column);
    @include responsive(sm) {
        padding: 3rem 0;
    }
    gap: 1rem;

    background-color: #bdb5fa;
}

.image {
    width: 100%;
    height: 100%;

    object-fit: cover;
}

.footer {
    padding: 2.4rem 0;

    ul {
        @include flex;
        @include responsive(xs) {
            @include flex(center, center, column);
        }

        gap: 1.5rem;
        margin: 0;
        padding: 0;

        li {
            @include responsive(xs) {
                font-size: 1.8rem;
                line-height: unset;
            }

            color: #fff;
            font-size: 2rem;
            font-family: $ff-text;
        }

        button {
            color: #fff;

            background: none;
            border: none;
            cursor: pointer;

            &:hover {
                color: #e0e0e0;
            }
        }
    }
}
