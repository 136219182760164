@import 'scss/mixins';

@import 'scss/fonts';

@import 'scss/variables';

.container {
    @include responsive(xs) {
        display: none;

        visibility: hidden;
    }
    display: block;
    width: 100%;

    visibility: visible;
}

.containerMobile {
    @include responsive(xs) {
        display: block;

        visibility: visible;
    }
    display: none;
    padding: 2rem;

    color: $black;

    visibility: hidden;
}

.separator {
    @include responsive(xs) {
        display: none !important;
    }
    margin: 1.6rem 0;
}

.list {
    @include flex(space-between, flex-start, row);
    @include responsive(xs) {
        @include flex(flex-start, flex-start, column);
        gap: 1.5rem;
    }
    @include responsive(md) {
        gap: 2rem;
    }
    margin: 0;
    padding: 0;

    list-style: none;
}

.footer {
    @include flex(flex-start, flex-start, column);
    @include responsive(xs) {
        @include flex(center, stretch, column);
        margin-top: 2.2rem;

        font-size: 1.2rem;
        font-family: $ff-text;
        white-space: normal;
        text-align: center;
    }
    gap: 1rem;
    margin-top: 8.5rem;

    font-size: 1.4rem;
    font-family: $ff-micro;
    line-height: 1.8rem;
    white-space: pre-wrap;

    & > button {
        @include responsive(xs) {
            align-self: center;
        }
    }
}
