@import 'scss/fonts';

@import 'scss/variables';

@import 'scss/mixins';

.logo {
    @include responsive(xs) {
        width: 10rem;
        inset: 1.5rem;
    }
    position: absolute;
    inset: 3rem;
}

.mainContainer {
    @include flex;
    @include responsive(xs) {
        @include flex(center, center, column);
        padding: 2rem;
    }
    min-height: 100dvh;
    padding: 7rem 0;

    background-color: $porcelain;

    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: contain;
}

.printerImage {
    @include responsive(xs) {
        width: 100%;
        margin-top: 3rem;
    }
    width: 50%;
}

.rightContainer {
    @include flex(center, center, column);
    @include responsive(md) {
        @include flex(space-around, center, column);
        height: 100%;
    }
    gap: 3rem;
    height: auto;
}

.check {
    @include responsive(xs) {
        display: block;
        margin-inline: auto;
    }

    width: 4.6rem;
    aspect-ratio: 1;
}

.title {
    @include responsive(xs) {
        text-align: center;
    }
    margin: 2rem 0;

    font: $title;
    font-size: 4rem;
    line-height: normal;
    white-space: pre-wrap;
}

.body {
    @include responsive(xs) {
        white-space: normal;
        text-align: center;
    }
    font: $paragraph;
    line-height: normal;
    white-space: pre-wrap;
}
