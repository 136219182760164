@use 'sass:color';

@import 'scss/mixins';

@import 'scss/variables';

@import 'scss/fonts';

.link {
    @include responsive(xs) {
        margin-bottom: 2rem;
    }
    color: $blue;
    font: $paragraph;
    text-decoration: none;

    background: none;

    border: none;

    transition: 0.2s;

    &:hover {
        color: color.adjust($blue, $lightness: 5%);
        text-decoration: none;
    }

    &:active {
        color: color.adjust($blue, $lightness: -10%);
        text-decoration: none;
    }
}
