@use 'sass:color';

@import 'scss/fonts';

@import 'scss/variables';

@import 'scss/mixins';

%button-base {
    padding: 1.2rem 1.8rem;

    font: $paragraph-greek;
    font-size: 1.6rem;

    border-radius: 1.2rem;

    transition: 0.2s;

    &:active {
        transform: translateY(3%);
    }

    &:disabled {
        transform: none;
        cursor: not-allowed;
    }
}

%button-square {
    min-width: 24.1rem;
    padding: 1.4rem 2.4rem;

    font-weight: 400;

    font-size: 1.6rem;
    font-family: $ff-text;
    line-height: 2.4rem;

    border: none;
    border-radius: none;
}

.container {
    &Primary {
        @extend %button-base;
        color: $white;

        background-color: $blue;

        border: none;

        &:hover {
            background-color: color.adjust($blue, $lightness: -5%);
        }

        &:active {
            background-color: color.adjust($blue, $lightness: -20%);
        }

        &:disabled {
            background-color: rgba($color: $blue, $alpha: 50%);

            transform: none;
            cursor: not-allowed;
        }
    }

    &Secondary {
        @extend %button-base;
        color: $blue;

        background-color: transparent;

        border: 1px solid $blue;

        &:hover {
            background-color: rgba($light-blue, 5%);
        }

        &:active {
            background-color: rgba($light-blue, 10%);
        }
    }

    &Square {
        @extend %button-square;

        color: $white;

        background: $black;

        &:disabled {
            background-color: #dbdbdb;
        }
    }

    &SquareInverted {
        @extend %button-square;

        color: $black;

        background: $white;
    }
}
