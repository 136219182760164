@import 'scss/mixins';

@import 'scss/fonts';

@import 'scss/variables';

.container {
    @include background-image(
        '/assets/up_to_date_background.webp',
        cover,
        center
    );
    @include flex(flex-start, center, column);
    @include responsive(md) {
        background-position-x: 28%;
    }
    @include responsive(xs) {
        @include flex(center, stretch, column);
        min-width: 35.7rem;
        min-height: 60rem;
        padding: 0;

        color: $black;
        text-align: left;

        background: none;
    }
    gap: 2.8rem;

    min-width: 100vmin;
    min-height: 100vmin;
    padding: 9rem 4rem;

    color: $white;

    white-space: pre-line;
    text-align: center;
}

.mobileBackground {
    @include responsive(xs) {
        display: block;
    }
    display: none;
}

.title {
    @include responsive(md) {
        font-size: 5rem;
        line-height: 5rem;
    }
    @include responsive(xs) {
        margin-top: 70vmin 70vmax;
        padding: 0 2rem;

        font-size: 3rem;
        line-height: 3rem;
        white-space: normal;
    }
    font: $title;
}

.content {
    @include responsive(md) {
        font-size: 1.5rem;
        line-height: 2.3rem;
    }
    @include responsive(xs) {
        padding: 0 2rem;

        white-space: normal;
    }
    font: $paragraph;
}

.button {
    @include responsive(xs) {
        margin-bottom: 3rem;
        place-self: center center;
    }
}

.carbonNeutralImage {
    text-align: center;
}
