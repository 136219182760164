@import 'scss/fonts';

@import 'scss/mixins';

@import 'scss/variables';

.heroContainer {
    @include flex(flex-start, flex-start, column);
    @include background-image(
        $url: '/assets/hero_us_background.webp',
        $size: cover,
        $position: center top
    );
    @include responsive(lg) {
        @include background-image(
            $url: '/assets/hero_us_background.webp',
            $size: cover,
            $position: center
        );
    }
    @include responsive(md) {
        @include background-image(
            $url: '/assets/hero_us_background.webp',
            $size: cover,
            $position: 50%
        );
        padding: 2rem;
    }
    @include responsive(xs) {
        height: 80dvh;
        padding: 4rem 1.6rem;
    }

    height: 100dvh;
    padding: 4rem;

    color: $white;
}

.instantInkNavigationLogo {
    width: 15.8rem;
    height: auto;
}

.emptySpace {
    flex: 1;
}

.mainTitle {
    @include flex(space-between, flex-end);
    @include responsive(xs) {
        @include flex(flex-start, flex-start, column-reverse);
        gap: 1.6rem;
    }
    @include responsive(md) {
        white-space: normal;
    }

    width: 100%;

    white-space: pre-wrap;
}

.textContainer {
    @include flex(flex-start, flex-start, column);
    gap: 1rem;

    h1 {
        @include responsive(xs) {
            width: 15ch;
            margin-bottom: 1.6rem;

            font-size: 4.2rem;
        }
        @include responsive(md) {
            max-width: 64.7rem;
        }
        font-size: 4.8rem;

        font-family: $ff-micro;
    }
}
