@import 'variables';

// New fonts, likely to deprecate all the other variables
$ff-display: 'Forma DJR Display';
$ff-micro: 'Forma DJR Micro';
$ff-micro-medium: 'Forma DJR Micro Medium';
$ff-micro-light: 'Forma DJR Micro Light';
$ff-text: 'Forma DJR Text';
$ff-ui: 'Forma DJR UI';

// Scalable font sizes
$fs-main-title: (
    lg: 9.6rem,
    md: clamp(6.4rem, 2.852rem + 4.62vw, 9.5rem),
    xs: clamp(4rem, 1.8rem + 5.867vw, 6.3rem),
);
$fs-h1: (
    lg: 6.4rem,
    md: clamp(5.6rem, 4.799rem + 1.043vw, 6.3rem),
    xs: clamp(4.8rem, 6.062rem + -3.365vw, 5.5rem),
);
$fs-h2: (
    lg: 4.8rem,
    md: clamp(4.4rem, 4.057rem + 0.447vw, 4.7rem),
    xs: clamp(4rem, 4.541rem + -1.442vw, 4.3rem),
);
$fs-h3: (
    lg: 4rem,
    md: clamp(3.6rem, 3.257rem + 0.447vw, 3.9rem),
    xs: clamp(3.2rem, 2.857rem + 0.447vw, 3.5rem),
);
$fs-h4: (
    lg: 3.6rem,
    md: clamp(3.2rem, 2.857rem + 0.447vw, 3.5rem),
    xs: clamp(2.8rem, 2.513rem + 0.765vw, 3.1rem),
);
$fs-body: (
    lg: 2.4rem,
    md: clamp(2rem, 1.657rem + 0.447vw, 2.3rem),
    xs: clamp(1.6rem, 1.313rem + 0.765vw, 1.9rem),
);
$fs-body-small: (
    lg: 2rem,
    md: clamp(1.6rem, 1.257rem + 0.447vw, 1.9rem),
    xs: clamp(1.2rem, 0.913rem + 0.765vw, 1.5rem),
);
$fs-footnote: (
    lg: 1.6rem,
    md: clamp(1.2rem, 0.857rem + 0.447vw, 1.5rem),
    xs: clamp(1rem, 0.904rem + 0.255vw, 1.1rem),
);

// Composed fonts
$title: normal normal 400 6.4rem / 6.4rem 'Forma DJR Display';
$title-mobile: normal normal 400 3.6rem / 4.4rem 'Forma DJR Display';
$big-title: normal normal 400 9rem / 9rem 'Forma DJR Display';
$subtitle: normal normal 400 1.2rem / 1.6rem 'Forma DJR Micro';
$paragraph: normal normal 400 1.6rem / 2.4rem 'Forma DJR Micro';
$paragraph-disp: normal normal 400 1.6rem / 2.4rem 'Forma DJR Display';
$paragraph-alt: normal normal 400 1.6rem / 2.4rem 'Forma DJR Display';
$paragraph-greek: normal normal 400 1.4rem / 1.8rem 'Forma DJR Text';

// Titles

$h1-desktop-l: normal normal 400 9rem / 9rem 'Forma DJR Display';
$h1-desktop-m: normal normal 400 6.4rem / 6.4rem 'Forma DJR Display';
$h1-desktop-xl: normal normal 400 11.6rem / 11.6rem 'Forma DJR Display';
$h1-desktop-xs: normal normal 400 3.6rem / 4.4rem 'Forma DJR Display';
$h2-desktop-l: normal normal 400 6.4rem / 7.2rem 'Forma DJR Display';
$h2-desktop-m: normal normal 400 4.8rem / 5.6rem 'Forma DJR Display';
$h2-desktop-xl: normal normal 400 8.4rem / 9.2rem 'Forma DJR Display';
$h2-desktop-xs: normal normal 400 2.8rem / 3.6rem 'Forma DJR Display';
$h3-desktop-l: normal normal 400 4rem / 4rem 'Forma DJR Display';
$h3-desktop-m: normal normal 400 3.2rem / 4rem 'Forma DJR Display';
$h3-desktop-xl: normal normal 400 4rem / 4rem 'Forma DJR Display';
$h3-desktop-xs: normal normal 400 2.4rem / 3.2rem 'Forma DJR Display';
$h4-desktop-l: normal normal 400 2.4rem / 3.4rem 'Forma DJR Micro';
$h4-desktop-m: normal normal 400 2rem / 2.8rem 'Forma DJR Micro';
$h4-desktop-xl: normal normal 400 2.4rem / 3.2rem 'Forma DJR Micro';
$h4-desktop-xs: normal normal 400 2rem / 2.8rem 'Forma DJR Micro';
$h4-medium-desktop-m: normal normal 400 2rem / 2.8rem 'Forma DJR Micro Medium';
$h4-medium-desktop-xl: normal normal 400 2.4rem / 3.2rem
    'Forma DJR Micro Medium';
$h4-medium-desktop-xs: normal normal 400 2rem / 2.8rem 'Forma DJR Micro Medium';
$h5-desktop-l: normal normal 400 2rem / 2.8rem 'Forma DJR Micro';
$h5-desktop-m: normal normal 400 1.6rem / 2.6rem 'Forma DJR Micro';
$h5-desktop-xl: normal normal 400 2rem / 2.8rem 'Forma DJR Micro';
$h5-desktop-xs: normal normal 400 1.6rem / 2.6rem 'Forma DJR Micro';
$h5-medium-desktop-m: normal normal 400 1.6rem / 2.6rem 'Forma DJR Micro Medium';
$h5-medium-desktop-xl: normal normal 400 2.4rem / 2.8rem
    'Forma DJR Micro Medium';
$h5-medium-desktop-xs: normal normal 400 1.6rem / 2.6rem
    'Forma DJR Micro Medium';

// Body

$body-desktop-l: normal normal 400 1.6rem / 2.4rem 'Forma DJR Micro';
$body-desktop-m: normal normal 400 1.6rem / 2.4rem 'Forma DJR Micro';
$body-desktop-tinier: normal normal 400 1.2rem / 1.8rem 'Forma DJR Micro';
$body-desktop-tiny: normal normal 400 1.4rem / 1.8rem 'Forma DJR Micro';
$body-desktop-pill-button: normal normal 400 1.4rem / 2.4rem 'Forma DJR Micro';
$body-desktop-xl: normal normal 400 1.6rem / 2.4rem 'Forma DJR Micro';
$body-desktop-xs: normal normal 400 1.6rem / 2rem 'Forma DJR Micro';
$body-medium-desktop-sl: normal normal 400 1.6rem / 2.4rem
    'Forma DJR Micro Medium';
$body-medium-desktop-xl: normal normal 400 1.6rem / 2.4rem
    'Forma DJR Micro Medium';
$caption-desktop-xl: normal normal 400 1.2rem / 1.6rem 'Forma DJR Micro';
$caption-desktop-xs: normal normal 400 1.2rem / 1.6rem 'Forma DJR Micro';
$caption-medium-desktop-xl: normal normal 400 1.2rem / 1.6rem
    'Forma DJR Micro Medium';
$caption-medium-desktop-xs: normal normal 400 1.2rem / 1.6rem
    'Forma DJR Micro Medium';
$label-desktop-xl: normal normal 400 1.6rem / 2.4rem 'Forma DJR Micro Medium';
$label-desktop-xs: normal normal 400 1.6rem / 2.4rem 'Forma DJR Micro Medium';
$number-desktop-l: normal normal 400 14rem / 14rem 'Forma DJR Micro';
$number-desktop-m: normal normal 400 10rem / 10rem 'Forma DJR Micro';
$number-desktop-xl: normal normal 400 18rem / 18rem 'Forma DJR Micro';
$number-desktop-xs: normal normal 400 6rem / 6rem 'Forma DJR Micro';
$number-not-labeled: normal normal 400 14rem / 16.8rem 'Forma DJR Micro';

// Footer

$footer-desktop: normal normal 400 1.4rem / 2rem 'Forma DJR Micro';
$footer-mobile: normal normal 400 1.6rem / 2.4rem 'Forma DJR Micro';

// Comparison Table

$comparison-table-feature-mobile: normal normal 400 1.4rem / 2.4rem
    'Forma DJR Micro';

// Printer
$modal-tiny-text: normal normal 400 1.2rem / 2.4rem 'Forma DJR Micro';
$printer-type: normal normal 400 1.8rem / 2.6rem 'Forma DJR Micro';
$steps-title: normal normal 400 4.8rem / 5.6rem 'Forma DJR Micro';

// Checkout
$card-section-title: normal normal 400 1.8rem / 1.8rem 'Forma DJR Display';
$card-subtitle: normal normal 400 1.5rem / 2rem 'Forma DJR Micro';
$card-title: normal normal 400 2rem / 2rem 'Forma DJR Display';
$contact-body: normal normal 400 1.4rem / 2rem 'Forma DJR Display';
$contact-title: normal normal 400 2.4rem / 3rem 'Forma DJR Display';
$modal-subtitle: normal normal 400 1.6rem / 1.6rem 'Forma DJR Micro';
$modal-title: normal normal 400 2.4rem / 3.2rem 'Forma DJR Display';
$price-plan: normal normal 400 2rem / 2rem 'Forma DJR Text';
$info-box: normal normal 400 1.8rem / 3rem 'Forma DJR Display';
$printer-name: normal normal 400 2.4rem / 4rem 'Forma DJR Display';
$serial-number: normal normal 400 1.6rem / 3.2rem 'Forma DJR Micro Medium';

// FAQS
$faqs-h4-xs: normal normal 400 2rem / 2.8rem 'Forma DJR Display';
$faqs-h4-desktop: normal normal 400 3.2rem / 3.6rem 'Forma DJR Display';
$faqs-h5-desktop: normal normal 400 2.4rem / 3.2rem 'Forma DJR Display';
$faqs-h5-xs: normal normal 400 1.8rem / 2rem 'Forma DJR Display';
$faqs-title-answer-desktop: normal normal 400 1.6rem / 3rem 'Forma DJR Display';
$faqs-title-question-desktop: normal normal 500 1.6rem / 2rem
    'Forma DJR Display';

// Terms and services
$tas-title: normal normal 400 3.2rem / 2.4rem 'Forma DJR Display';
$tas-subtitle: normal normal 400 2.4rem / 2.4rem 'Forma DJR Display';

// Comparison Table
$table-title: normal normal 400 2.4rem / 5.4rem 'Forma DJR Display';

// home
$subtitle-simple-print: normal normal 400 1.6rem / 2.8rem 'Forma DJR Micro';
