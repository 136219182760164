@import 'scss/mixins';

@import 'scss/variables';

@import 'scss/fonts';

.content {
    @include responsive(xs) {
        padding: 4.7rem 1rem;
    }
    height: 100vh;
    padding: 4.7rem 20%;

    background-color: $white;

    border-top: 1px solid $seventh-gray;
}

.title {
    margin-bottom: 20px;

    font: $tas-title !important;
    line-height: 3.6rem !important;
    letter-spacing: 0.02em !important;
    text-align: center;
}

.indexLinks {
    margin: 0 20px;

    color: $ninth-gray;
    font: $body-desktop-l !important;
    text-align: left;

    list-style: decimal;

    a {
        color: $blue !important;
    }

    p {
        margin: 0;
    }

    b {
        font-weight: normal;
    }

    li {
        margin: 1rem 0;
    }

    ol {
        list-style: lower-latin;
    }
}
