@use 'sass:color';

@import 'scss/mixins';

@import 'scss/variables';

@import 'scss/fonts';

.footer {
    @include flex(space-between, flex-end, row);
    @include responsive(md) {
        @include flex(center, center, column);
        gap: $sp-700;
    }
}

.summary {
    @include flex(flex-start, flex-start, column);
    @include responsive(md) {
        @include flex(center, center, column);
        text-align: center;
    }

    gap: $sp-200;
}

.summaryDetails {
    line-height: 105%;

    p {
        margin: 0;
        padding: 0;
    }
}

.footnote {
    @include font-size($fs-footnote);
    @include responsive(xs) {
        @include font-size($fs-body);
    }

    margin: 0;

    font-weight: $fw-light;

    a {
        color: $blue;
        text-decoration: none;

        &:hover {
            color: color.adjust($color: $blue, $lightness: 10%);

            transform: translateY(-5%);
        }

        &:active {
            color: color.adjust($color: $blue, $lightness: -10%);

            transform: translateY(5%);
        }
    }
}
