@import 'scss/mixins';

@import 'scss/variables';

.configPanel {
    @include flex(center, space-between, column);
    gap: 2rem;
    margin: 1rem;
    padding: 1rem;

    border: 0.1rem solid $border-gray;
    border-radius: 1rem;
}

.form {
    @include flex(space-between, center, row);
}
