@import 'scss/mixins';

@import 'scss/variables';

@import 'scss/fonts';

.container {
    @include flex(flex-start, flex-start, column);
    height: 100dvh;
}

.mainContainer {
    @include flex(space-between, center, column);
    @include responsive(md) {
        padding: 4rem 3rem;
    }
    @include responsive(xs) {
        padding: 0;
    }
    flex: 1;
    width: 100%;
    padding: 4rem 6rem;

    background: $light-white;

    border-top: 1px solid $border-gray;
}

.card {
    @include responsive(xs) {
        width: 33rem !important;
        margin: 0 auto;
    }
    width: 30% !important;
    min-height: 38rem;

    color: $gray-85 !important;
}

.button {
    width: 12rem;
    height: 4.8rem;
    margin: 3rem 0;

    font: $body-desktop-m !important;
}
