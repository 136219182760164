@import 'scss/mixins';

@import 'scss/fonts';

@import 'scss/variables';

.container {
    @include flex(space-between, center, row);
    @include responsive(xs) {
        @include flex(center, center, column);
        min-width: 35.7rem;
        padding: 3.2rem 2rem;
    }

    gap: 6rem;

    min-width: 100vmin;
    min-height: 100vmin;

    padding: 9rem 4rem;

    white-space: pre-line;
}

.left {
    @include flex(flex-end, center, row);
    @include responsive(xs) {
        width: 100%;
    }
    width: 50%;
}

.image {
    @include responsive(xs) {
        height: 40rem;
    }
    width: 68rem;
    height: 89rem;
    object-fit: cover;
}

.right {
    @include flex(center, flex-start, column);
    @include responsive(xs) {
        gap: 0;
        width: 100%;
    }

    gap: 4rem;
    width: 43%;

    height: 100%;
}

.button {
    @include responsive(xs) {
        align-self: center;
        margin-top: 3rem;
        margin-left: 0;
    }

    margin-left: calc(2rem + 6rem);
}
