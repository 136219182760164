@import 'scss/mixins';

@import 'scss/variables';

@import 'scss/fonts';

.container {
    @include background-image('/assets/hero_us_background.webp', cover, start);
    @include flex(flex-end, flex-start, column);
    @include responsive(xs) {
        min-width: 35.7rem;
        min-height: 50vh;
        margin-top: 26.2rem;
        padding: 3.2rem 2rem;

        background: none;
    }

    gap: 3rem;

    min-width: 100vmin;
    min-height: 100vmin;

    padding: 9rem 4rem;

    color: $white;

    white-space: pre-line;
}

.mobileBackground {
    @include responsive(xs) {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        display: block;
        width: 100vw;
        min-width: 35.7rem;
        height: 60rem;
        object-fit: cover;
        object-position: 100%;
    }
    display: none;
}

.logo {
    @include responsive(xs) {
        top: 3.2rem;
        left: 2rem;
    }
    position: absolute;
    top: 4rem;
    left: 4rem;
}

.mainSection {
    @include responsive(xs) {
        margin-top: 20rem;
    }
    justify-self: center;
    width: 100%;
}

.subtitle {
    @include responsive(xs) {
        font-size: 1rem;
    }
    font: $subtitle;
}

.titleContainer {
    @include flex(space-between, flex-end, row);
    @include responsive(xs) {
        @include flex(space-between, flex-end, column);
    }
}

.title {
    @include responsive(lg) {
        font-size: 6rem;
        line-height: 7rem;
    }
    @include responsive(md) {
        font-size: 5rem;
        line-height: 5rem;
    }
    @include responsive(xs) {
        font: $title-mobile;
        font-size: 2.7rem;
        line-height: 3.5rem;
        white-space: normal;
    }
    font: $big-title;
    white-space: pre-wrap;
}

.carbonNeutralImage {
    place-self: flex-end flex-end;
}

.separator {
    @include responsive(xs) {
        margin: 2rem 0;
    }
    margin: 3.2rem 0;
    padding: 0;

    opacity: 0.5;
}

.iconContainer {
    @include flex(space-between, flex-start, row);
    @include responsive(xs) {
        @include flex(flex-start, flex-start, column);
        gap: 1rem;
        margin: 1rem 0;
    }
    width: 100%;
    margin: 0;
    padding: 0;
}

.bottomSection {
    @include flex(center, flex-start, column);
    @include responsive(xs) {
        @include flex(center, stretch, column);
    }
    gap: 1.5rem;
}

.disclaimer {
    @include responsive(xs) {
        color: $black;
        white-space: initial;
        text-align: center;
    }
    font: $body-desktop-tiny;
}
