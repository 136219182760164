@import 'scss/fonts';

@import 'scss/variables';

@import 'scss/mixins';

%printer {
    width: 6rem;
    height: 6rem;
    padding: 1rem;
    object-fit: contain;
}

.headerContainer {
    text-align: center;
}

.headerTitle {
    @include responsive(xs) {
        white-space: pre-wrap;
    }

    margin: 0;
    padding: 0;

    font: $paragraph;
    white-space: normal;
}

.printer {
    @extend %printer;
}

.selectedPrinter {
    @extend %printer;

    background-color: $blue;

    border-radius: 50%;
}
