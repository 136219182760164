@import 'scss/variables';

@import 'scss/mixins';

@import 'scss/fonts';

.container {
    width: 100%;

    text-align: left;
}

.pageTitle {
    @include responsive(xs) {
        padding: 1rem 0;

        font: $h3-desktop-xs;
        line-height: 2.8rem;
        letter-spacing: 0.02em;
        text-align: center;
    }
    padding-bottom: 2.6rem;

    color: $gray-90;

    font: $h3-desktop-m;
    line-height: 3.2rem;
    letter-spacing: 0.02em;
}
