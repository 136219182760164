@import 'scss/mixins';

@import 'scss/variables';

@import 'scss/fonts';

.container {
    @include flex(center, center, row);
    @include responsive(xs) {
        @include flex(center, center, column);
        gap: 5rem;
        min-width: 35.7rem;
        padding: 3.2rem 2rem;
    }
    gap: 3rem;

    min-width: 100vmin;
    min-height: 100vmin;

    white-space: pre-line;
}

.left {
    @include flex(center, flex-end, column);
    @include responsive(xs) {
        width: 100%;
    }
    width: 40%;
}

.image {
    width: 100%;
}

.right {
    @include flex(center, flex-start, column);
    @include responsive(xs) {
        width: 100%;
    }
    width: 35%;
}

.textContainer {
    @include responsive(xs) {
        @include flex(stretch, stretch, column);
        width: 100%;

        white-space: normal;
    }
    white-space: pre-wrap;
}

.subtitle {
    @include responsive(xs) {
        font-size: 1.2rem !important;
    }
    font: $subtitle;

    font-size: min(1.6rem, 1.2vw) !important;
}

.title {
    @include responsive(md) {
        font-size: min(5rem, 4.3vw) !important;
        line-height: min(5rem, 4.3vw) !important;
    }
    @include responsive(xs) {
        font-size: 3rem !important;
        line-height: 3rem !important;
    }
    margin-bottom: 3rem;

    font: $title;
    font-size: min(6.4rem, 4.2vw) !important;
    line-height: min(6.4rem, 4.2vw) !important;
}

.button {
    @include responsive(xs) {
        place-self: center center;
    }
}
