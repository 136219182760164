@import 'scss/variables';

@import 'scss/fonts';

@import 'scss/mixins';

.container {
    @include flex(center, center, column);
    height: 100%;

    text-align: center;
}

.pricePerMonth {
    color: $gray-90;
    font: $price-plan !important;
    line-height: normal !important;
}

.text {
    color: $gray-85;
    font: $paragraph-greek;
    font-size: 1.4rem;
}
