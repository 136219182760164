@import 'scss/mixins';

@import 'scss/fonts';

@import 'scss/variables';

.container {
    min-height: 100dvh;

    font-family: $ff-ui;

    white-space: pre-wrap;

    background-color: $third-gray;

    p {
        @include font-size($fs-body);
    }
}

.nav {
    background-color: $white;

    .instantInkLogo {
        height: 2.5rem;
        margin: $sp-700 0 $sp-700 16.8rem;
    }
}

.header {
    @include responsive(xs) {
        padding: 0 $sp-400;

        white-space: normal;
        text-align: center;
    }
    @include responsive(md) {
        @include flex(center, center, column);
        gap: $sp-400;
    }
    @include flex(center, center, row);
    gap: $sp-800;

    .printerImage {
        width: 43.2rem;
    }

    .headerTitleContainer {
        @include flex(flex-start, center, row);
        @include responsive(xs) {
            @include flex(center, center, column);
        }

        gap: $sp-200;

        & > h1 {
            @include font-size($fs-h2);
        }
    }
}

.main {
    position: relative;

    margin-top: $sp-400;

    padding: $sp-300 $sp-500 19.2rem;

    color: $white;
    text-align: center;

    background-color: $light-blue;

    & > h2 {
        @include font-size($fs-h3);
        margin-bottom: $sp-200;
    }

    & > ul {
        @include responsive(xs) {
            @include flex(center, stretch, column);
            top: 17.4rem;

            flex-wrap: nowrap;
        }
        @include responsive(md) {
            flex-wrap: wrap;
        }
        @include flex(center, center, row);
        position: absolute;

        top: 13.7rem;
        left: 50%;

        width: min(144.8rem, calc(100vw - (4.4rem * 2)));

        margin: 0;
        padding: 0;

        color: $black;

        background-color: $white;
        border-radius: $sp-200;
        box-shadow: 0 0 24px -7px rgb(0 0 0 / 75%);
        transform: translateX(-50%);
    }
}
