@import 'scss/fonts';

@import 'scss/variables';

.label {
    position: absolute;
    z-index: 1;

    display: block;
    width: 100%;
    min-width: 0;
    max-width: calc(100% - (0rem + 3.2rem));

    color: $placeholder-gray;
    font: $paragraph;
    font-size: 1.4rem;
    white-space: nowrap;
    text-overflow: ellipsis;

    transform: translate(1.6rem, 1.2rem);
    transform-origin: top left;
    cursor: text;

    transition:
        font-family 300ms linear,
        transform 150ms cubic-bezier(0, 0, 0.2, 1);

    pointer-events: none;
    will-change: font-family, transform;
}
