@import 'scss/fonts';

.container {
    width: 100%;
    margin-top: 4.7rem;

    text-align: center;
}

.title {
    margin-bottom: 1.4rem;

    font: $tas-title !important;
}

.subtitle {
    margin-bottom: 1.4rem;

    font: $tas-subtitle !important;
}
