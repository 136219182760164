@import 'scss/mixins';

@import 'scss/fonts';

@import 'scss/variables';

.container {
    @include flex(center, center, row);
    @include responsive(xs) {
        @include flex(center, center, column-reverse);
        gap: 3.2rem;
        min-width: 35.7rem;
        padding: 3.2rem 2rem;
    }
    gap: 10rem;

    min-width: 100vmin;
    min-height: 100vmin;

    padding: 9rem 4rem;

    white-space: pre-line;
}

.left {
    @include flex(center, flex-start, column);
    @include responsive(xs) {
        width: 100%;
    }
    width: 50%;

    // TODO: Remove when @veneer is fully removed
    ul {
        margin: 0;
        padding: 0;
    }
}

.point {
    padding: 3.2rem 0;

    border-top: 1px solid $border-gray;
    border-bottom: 1px solid $border-gray;

    &:first-child {
        border-bottom: none;
    }

    &:last-child {
        border-top: none;
    }
}

.title {
    @include responsive(md) {
        font-size: 5.5rem;
        line-height: 5.5rem;
    }
    @include responsive(xs) {
        font-size: 3rem;
        line-height: 3rem;
        white-space: normal;
    }
    margin-bottom: 3rem;

    font: $title;
}

.content {
    @include responsive(md) {
        font-size: 1.7rem;
        line-height: 2.5rem;
        white-space: initial;
    }
    font: $paragraph;
}

.button {
    @include responsive(xs) {
        margin-top: 3rem;
    }
    margin-top: 5rem;
}

.image {
    @include responsive(xs) {
        height: 36rem;
    }
    width: 68rem;
    height: 89rem;
    object-fit: cover;
}

.right {
    @include flex(center, flex-end, column);
    @include responsive(xs) {
        width: 100%;
    }

    gap: 6rem;
    width: 50%;

    height: 100%;
}
