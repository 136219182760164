@import 'scss/fonts';

@import 'scss/mixins';

.headerContainer {
    @include flex(center, center, column);
    text-align: center;
}

.title {
    font: $title;
    font-size: 3.2rem;
    line-height: normal;
}

.subtitle {
    font: $subtitle;
    white-space: pre-wrap;
}
