@import 'scss/mixins';

@import 'scss/variables';

@import 'scss/fonts';

$width: min(95%, 128rem);

.instantInkLogo {
    position: absolute;

    inset: $sp-500 clamp($sp-300, -0.343rem + 3.571vw, $sp-600);
}

%container {
    @include responsive(xs) {
        white-space: normal;
    }
    font-family: $ff-ui;
    white-space: pre-wrap;

    h1 {
        @include font-size($fs-h1);
    }

    h2 {
        @include font-size($fs-h2);
    }

    p {
        @include font-size($fs-body);
    }
}

.upgradeButton {
    @include font-size($fs-body);
    margin-top: $sp-200;
    padding: $sp-200 $sp-300;
}

.hero {
    @extend %container;
    @include background-image('/assets/up_to_date_background.webp', cover, 45%);
    @include flex(center, center);
    position: relative;

    gap: $sp-200;

    height: 80dvh;

    color: $white;
    text-align: center;
}

.content {
    @include flex(center, center, column);
    width: $width;
    margin-bottom: 8vh;
}

// Slide 2

.notReady {
    @extend %container;
    @include responsive(xs) {
        @include flex(center, center, column);
        gap: clamp($sp-300, 1.837rem + 1.502vw, $sp-500);
    }
    @include flex(space-between, center, row);
    width: $width;
    margin: 4rem auto;

    color: $black;

    p {
        margin-top: $sp-200;
    }
}

.leftContainer {
    @include responsive(md) {
        width: 50%;

        white-space: normal;
    }
    @include responsive(xs) {
        width: 100%;
    }
}

.stamp {
    min-width: $sp-300;
}
