@import 'scss/fonts';

@import 'scss/variables';

@import 'scss/mixins';

.logo {
    @include responsive(md) {
        position: relative;
        top: 0;
        left: 0;

        display: block;

        width: 11.4rem;
        min-width: 11.4rem;
        height: 1.8rem;
        min-height: 1.8rem;

        margin: 1rem 1.2rem;
    }
    position: absolute;
    top: 4rem;
    left: 4rem;
}

.container {
    @include flex(space-between, flex-stretch, column);
    min-height: 100dvh;
}

.content {
    @include flex(center, flex-stretch, row);
    @include responsive(md) {
        @include flex(space-between, flex-stretch, column-reverse);
    }

    flex: 1;
}

.leftSide {
    @include flex(center, flex-stretch, column);
    @include responsive(md) {
        width: auto;
    }
    width: 50%;

    color: $white;
}

.topSection {
    @include flex(flex-end, flex-start, column);
    @include responsive(md) {
        min-height: unset;
        padding: 3rem 2rem;
    }
    height: 50%;
    min-height: 43.65rem;
    padding: 7.7rem;

    background: $red;
}

.bottomSection {
    @include responsive(md) {
        padding: 2.5rem 2rem;
    }
    height: 50%;
    padding: 4rem 7.7rem;

    background: $red-100;
}

.image {
    @include background-image(
        '/assets/not_found_aside.webp',
        cover,
        center 20%
    );
    @include responsive(md) {
        width: 100%;
        height: 100%;
    }
    @include responsive(xs) {
        min-height: 40vh;
    }
    flex: 1;
    width: 50%;
}

.title {
    @include responsive(md) {
        font-size: 3.4rem;
        line-height: 4rem;
        letter-spacing: 2%;
    }
    color: $white;
    font: $paragraph-greek;
    font-size: 5.4rem;
    line-height: 7.2rem;
    letter-spacing: 2%;
    white-space: pre-wrap;
}

.text {
    @include responsive(md) {
        width: 30ch;

        font-size: 1.8rem;
        line-height: 2.2rem;
        white-space: normal;

        &:nth-child(1) {
            font-size: 1.4rem;
        }
    }
    font: $paragraph-greek;
    font-size: 2.4rem;
    line-height: 3.2rem;
    white-space: pre-wrap;
}

.customButton {
    @include responsive(md) {
        padding: 0.8rem 2.3rem;

        font-size: 1.4rem;
        line-height: 2.4rem;
    }
    padding: 1.2rem 3.5rem;

    font: $paragraph-greek;
    font-size: 1.6rem;
    line-height: 2.4rem;

    background-color: $black;
}

.linkButton {
    margin: 0;
    margin-top: 3rem;
    padding: 0;

    color: $white;

    font: $paragraph-greek;
    font-size: 1.4rem;
    letter-spacing: 2%;
    text-decoration: underline;

    background: transparent;

    border: none;

    &:hover {
        background: transparent;
    }
}
