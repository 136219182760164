@import 'scss/fonts';

@import 'scss/variables';

@import 'scss/mixins';

.container {
    @include flex(center, space-around, column);
    @include responsive(md) {
        background-position: -20vw bottom;
        background-size: 93%;
    }
    @include responsive(xs) {
        padding: 0 2.4rem;

        background-size: 0.1%;
    }

    color: $background-footer;

    background-color: $porcelain;
    background-repeat: no-repeat;
    background-position: left bottom;
}
