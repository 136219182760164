@use 'sass:color';

@import 'scss/mixins';

@import 'scss/variables';

%container {
    @include responsive(md) {
        min-width: 72rem;
    }
    @include responsive(xs) {
        @include flex(center, center, column);
        width: 95vw;
        min-width: unset;
        max-height: 85vh;
    }
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 10;

    min-width: 85rem;
    max-height: 80vh;

    overflow: hidden auto;

    background-color: $white;

    border-radius: 1.5rem;
    box-shadow: 0 0 24px -7px rgb(0 0 0 / 75%);

    transform: translate(-50%, -50%);
}

.container {
    @extend %container;
    @include responsive(md) {
        padding: 4rem 1.7rem;
    }
    @include responsive(xs) {
        padding: 1rem;
    }
    padding: 4rem 4.5rem;
}

.containerWithoutPadding {
    @extend %container;
}

.forceFullWidth {
    width: 100%;
}

.closeButton {
    position: absolute;
    top: 2rem;
    right: 2rem;

    z-index: 9999;

    width: 3rem;
    height: 3rem;

    font-size: 1.5rem;

    background: rgba($white, 0.5);

    border: none;
    border-radius: 50%;

    transition: 0.2s;

    &:hover {
        background-color: color.adjust($gray, $lightness: -20%);
    }

    &:active {
        background-color: color.adjust($gray, $lightness: -30%);
    }
}

.contentContainer {
    @include responsive(xs) {
        margin-top: 5rem;
    }
    height: 100%;
    overflow: hidden auto;
}
