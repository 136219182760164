@import 'scss/variables';

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;

    width: 100vw;
    height: 100vh;

    background-color: rgba($black, 50%);

    backdrop-filter: blur(5px);

    pointer-events: visible;
}
