@import 'scss/mixins';

@import 'scss/variables';

@import 'scss/fonts';

.container {
    @include flex(center, stretch, column);
    @include responsive(xs) {
        @include flex(center, flex-start, column);
        gap: 3rem;
        min-width: 35.7rem;
        min-height: 120vmin;
        padding: 3.2rem 2rem;
    }
    gap: 3rem;

    min-width: 100vmin;
    min-height: 100vmin;

    padding: 9rem 4rem;

    color: $black;
}

.title {
    @include responsive(md) {
        font-size: 5rem;
        line-height: 5rem;
    }
    font: $title;
}

.disclaimersContainer {
    @include responsive(md) {
        font-size: 1.5rem;
        line-height: 2.3rem;
    }
    overflow: hidden;

    font: $body-desktop-m;

    span {
        text-transform: uppercase;
    }

    p {
        margin-bottom: 2rem;
    }

    ul {
        margin: 0;

        list-style: decimal;
    }
}

.disclaimerTitle {
    text-transform: uppercase;
}

.separator {
    margin: 1.6rem 0;
    padding: 0;

    border: none;
    border-top: 1px solid $border-gray;
}

.button {
    @include responsive(xs) {
        align-self: stretch;
    }
    align-self: center;
}
