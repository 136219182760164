@import 'scss/mixins';

.footer {
    @include flex(space-between, flex-end, row);
    @include responsive(xs) {
        margin: 3rem;
        padding: 0 3rem;
    }
    flex-grow: 1;
    width: 100%;
}

.button {
    min-width: 1.2rem;
}
