@use 'sass:color';

@import 'scss/mixins';

@import 'scss/variables';

@import 'scss/fonts';

.tab {
    overflow: hidden;

    color: $black;

    border-radius: 1.6rem;

    transition: 0.2s;

    &:hover {
        @include responsive(xs) {
            transform: none;
        }
        transform: translateY(-2%);
    }

    &:active {
        @include responsive(xs) {
            transform: none;
        }
        transform: translateY(2%);
    }

    &Label {
        @include flex(space-between, center, row);
        @include responsive(md) {
            font-size: 1.5rem;
            line-height: 2.3rem;
        }
        padding: 1.2rem;

        font-size: 1.6rem;

        font-family: $ff-text;
        line-height: 2.4rem;

        background: $white;

        cursor: pointer;

        transition: 0.2s;

        &::after {
            min-width: 2rem;
            min-height: 2rem;

            text-align: center;

            transition: all 0.2s ease-in-out;

            content: url('/assets/chevron_down.svg');
        }

        &:hover {
            @include responsive(xs) {
                background: $white;
            }
        }
    }

    &Content {
        @include responsive(md) {
            font-size: 1.5rem;
            line-height: 2.3rem;
        }
        height: 0;
        padding: 0 1.2rem;

        color: color.adjust($dark-gray, $lightness: -10%);
        font-size: 1.6rem;
        font-family: $ff-text;
        line-height: 2.4rem;

        background: $white;
        opacity: 0;

        transition: 0.2s;

        ul {
            list-style-type: disc;
        }
    }
}

.input {
    position: absolute;
    z-index: -1;

    opacity: 0;
}

.input:checked {
    + .tabLabel {
        border-bottom: 1px solid $border-gray;

        &::after {
            transform: rotate(180deg);
        }
    }

    ~ .tabContent {
        height: fit-content;
        padding: 1.6rem 1.2rem;

        opacity: 1;
    }
}
