@import 'scss/mixins';

@import 'scss/fonts';

@import 'scss/variables';

h1,
h2,
p {
    margin: 0;
    padding: 0;
}

%container-base {
    @include flex(center, flex-start, column);
    @include responsive(xs) {
        width: 100%;
        padding: 1rem 1.3rem;
    }
    gap: 0.3rem;
    width: min(51.5rem, 40vw);
    padding: 1rem 1.3rem;

    color: $black;

    background: transparent;

    border: none;

    border-radius: 1.2rem;
    cursor: pointer;

    transition: 0.1s;

    &:disabled {
        opacity: 0.5;
        filter: saturate(0);
    }
}

.container {
    @extend %container-base;
    border: 0.1rem solid $darker-gray;
}

.containerSelected {
    @extend %container-base;
    border: 0.3rem solid $blue;
}

.textContainer {
    @include flex(space-between, center, row);
    gap: 1rem;
    width: 100%;
}

.altTextContainer {
    @include flex(flex-start, center, row);
    gap: 0.8rem;
    width: 100%;

    p {
        color: $dark-gray;
    }
}

.title {
    @include responsive(xs) {
        font-size: 1.4rem;
    }
    margin: 0 !important;
    padding: 0 !important;

    font-weight: bold;
    font-size: 1.6rem;

    font-family: $ff-ui;
    text-align: left;
}

.subtitle {
    @include responsive(xs) {
        font-size: 1.4rem;
    }
    margin: 0 !important;
    padding: 0 !important;

    color: $blue;
    font: $body-desktop-l;
}
