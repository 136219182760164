@import 'scss/variables';

@import 'scss/fonts';

@import 'scss/mixins';

.container {
    @include responsive(xs) {
        max-width: unset;

        white-space: normal;
    }
    @include responsive(md) {
        white-space: normal;
    }

    max-width: 24.7rem;

    font-family: $ff-micro;

    white-space: pre-wrap;

    text-align: left;
}

.image {
    @include responsive(xs) {
        max-width: 4.7rem;
        max-height: 4.7rem;
    }

    max-width: 6rem;
    max-height: 6rem;
    margin-bottom: 1.5rem;
}

.title {
    @include responsive(xs) {
        margin-bottom: 0.5rem;

        font-size: 2.4rem;
    }
    margin-bottom: 3rem;

    font-weight: 400;
    font-size: 3.4rem;
}

.body {
    @include responsive(xs) {
        font-size: 1.4rem;
    }
    font-size: 1.6rem;
    overflow-wrap: break-word;
    hyphens: manual;
}
