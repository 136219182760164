@use 'sass:color';

@import 'scss/mixins';

@import 'scss/fonts';

@import 'scss/variables';

// All these !important are necessary because of veneer

.link {
    @include responsive(md) {
        font-size: 1.2rem;
        line-height: 2rem;
        letter-spacing: 0.02rem;
    }
    @include responsive(xs) {
        font-size: 1.2rem !important;
    }
    color: $white;

    font: $paragraph-greek !important;
    font-size: 1.4rem !important;
    line-height: 2rem !important;
    letter-spacing: 0.02rem !important;
    text-decoration: none !important;

    transition: 0.2s;

    &:hover {
        @include responsive(xs) {
            color: $white;
        }

        color: color.adjust($white, $lightness: -20%);
        text-decoration: none;
    }
}
