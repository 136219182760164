@import 'scss/variables';

@import 'scss/fonts';

@import 'scss/mixins';

.errorContainer {
    @include flex(flex-start, flex-start, row);
    gap: 0.6rem;
    width: 100%;
    height: 18px;
    margin-top: 1rem;
}

.errorMessage {
    margin: 0;
    padding: 0;

    color: $red-100;
    font: $paragraph-greek;
    line-height: 1.8rem;
    letter-spacing: 0.28px;
    text-overflow: ellipsis;
}

.icon {
    display: inline-block;
    box-sizing: border-box;
    width: 2.4rem;
    min-width: 1.5rem;
    max-width: calc(1.6rem - 0.2rem);
    height: calc(1.6rem - 0.2rem);
    min-height: 1.5rem;
    margin: 0;

    padding: 0;

    color: $white;

    text-align: center;

    background-color: $red-100;

    border-radius: 50%;

    user-select: none;
    fill: $white;
}
