@import 'scss/mixins';

@import 'scss/variables';

@import 'scss/fonts';

.root {
    margin-top: 1.2rem;
    padding-bottom: 4rem;

    li {
        margin-bottom: 1.2rem;
        margin-left: 1.2rem;

        color: $dark-gray;
        font-size: 1.6rem;
        line-height: 2rem;

        list-style: disc;
    }

    p {
        display: block;
        margin-bottom: 1.2rem;
    }
}

.accordionContent {
    div {
        font: $faqs-title-question-desktop;

        p {
            font: $faqs-title-answer-desktop;
        }
    }
}

.title {
    margin-left: 1rem;
    padding-bottom: 1.2rem;
}
