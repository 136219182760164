@import 'scss/variables';

@import 'scss/fonts';

.valueContainer {
    padding: 0 3rem;

    vertical-align: middle;
}

.valueText {
    margin: 0;
    padding: 0;

    color: $blue;
    font: $paragraph;
    font-size: 1.5rem;
}

input[type='checkbox'],
.checkbox {
    width: 1rem;
    height: 1rem;
    margin: 0;

    background: rgba($blue, 5%);

    outline: none;

    border: none;
    border-radius: 50%;
    cursor: unset;

    appearance: none;

    &:checked {
        background: $blue;
    }
}
