@import 'scss/fonts';

@import 'scss/variables';

@import 'scss/mixins';

h1,
p {
    margin: 0;
    padding: 0;
}

.container {
    @include flex(space-around, center, row);
    @include responsive(xs) {
        @include flex(center, center, column);
        min-height: 70rem;
        margin-bottom: 7rem;
    }

    height: 100vh;

    min-height: 80rem;
    overflow: hidden;
}

.imageContainer {
    @include responsive(md) {
        width: 70rem;
    }
    @include responsive(xs) {
        width: 25rem;
    }
    width: 80rem;
}

.rightSectionContainer {
    @include flex(center, flex-start, column);
    @include responsive(md) {
        width: 38.2rem;
    }
    @include responsive(xs) {
        @include flex(center, center, column);

        width: 32.7rem;

        text-align: center;
    }

    width: 51.5rem;
}

.title {
    @include responsive(md) {
        font: $h3-desktop-l !important;
    }
    @include responsive(xs) {
        text-align: center;
    }
    font: $h2-desktop-l !important;
    white-space: pre-wrap;
}

.subtitle {
    @include responsive(md) {
        font: $h5-desktop-xl !important;
    }
    @include responsive(xs) {
        white-space: normal;
    }

    font: $h4-desktop-xl !important;
    white-space: pre-wrap;
}

.summary {
    @include flex(flex-start, flex-start, column);
    @include responsive(xs) {
        @include flex(center, center, column);
        margin-top: 3.4rem;
    }
    margin: 10rem 0;
}

.topSection {
    @include flex(flex-start, flex-end, row);
}

.planType {
    @include responsive(md) {
        font: $h3-desktop-m !important;
        line-height: 3.5rem !important;
    }
    @include responsive(xs) {
        font: $h3-desktop-xs !important;
    }
    margin: 0 !important;

    font: $h3-desktop-l !important;
}

.printerModel {
    @include responsive(xs) {
        margin-left: 1.4rem !important;

        font: $h4-desktop-m !important;
    }
    margin: 0;
    margin-left: 1rem !important;

    color: $dark-gray;
    font: $h5-desktop-l !important;
}

.pagesPerMonth {
    @include responsive(xs) {
        margin-top: 1.6rem;

        font: $printer-type !important;
    }
    margin: 1rem 0 0;

    font: $h5-desktop-l !important;
}

.paperSuscription {
    @include responsive(xs) {
        font: $printer-type !important;
    }
    font: $h5-desktop-l !important;
}
