@use 'sass:color';

@import 'scss/mixins';

@import 'scss/variables';

@import 'scss/fonts';

.container {
    @include responsive(xs) {
        margin: 1rem;
        overflow-x: scroll;
    }

    position: absolute;
    z-index: 1;

    margin: 2rem 3rem;

    padding-top: 3.5rem;
    inset: 0;
}

.title {
    margin: 1rem;
    padding: 0;

    font: $title;
    font-size: 3rem;
}

.tableContainer {
    @include responsive(xs) {
        width: 100%;
    }

    width: 100%;
    margin: 1rem auto;

    text-align: center;
}
