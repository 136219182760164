@import 'scss/fonts';

@import 'scss/mixins';

.checkoutBanner {
    @include flex(space-between, center, row);
    @include responsive(xs) {
        @include flex(center, stretch, column);
        min-width: auto;

        text-align: center;
    }

    min-width: 56.8rem;

    h3 {
        @include font-size($fs-h3);
        color: $blue;
        font-weight: $fw-regular;
    }
}

.customButton {
    @include font-size($fs-body);
    padding: $sp-200 $sp-300;
}

.spacer {
    @include responsive(xs) {
        display: none;
    }
    display: block;
}
