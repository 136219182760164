@import 'scss/fonts';

@import 'scss/mixins';

.container {
    @include flex(flex-start, center, row);
    @include responsive(xs) {
        @include flex(flex-start, center, column);
        width: 100%;
        min-width: auto;
        padding: 2rem;
    }
    @include responsive(md) {
        @include flex(flex-start, center, column);
        width: 100%;
        min-width: auto;
        padding: 2rem;

        white-space: normal;
    }

    gap: 4.8rem;
    width: 70vw;

    min-width: max-content;
    max-width: 130rem;
    padding: 7.2rem 2rem 7.2rem 0;

    white-space: pre-wrap;
}

.title {
    @include responsive(md) {
        min-width: auto;

        text-align: center;
    }
    min-width: 62rem;

    font-size: 4.8rem;

    font-family: $ff-display;
}

.img {
    width: 50%;
    max-width: 48rem;
    height: auto;
}

.contentContainer {
    @include flex(center, flex-start, column);
    gap: 4rem;
}

.optionContainer {
    @include responsive(xs) {
        @include flex(center, center, column);
    }
    @include flex(space-between, flex-start, row);

    gap: 2.8rem;
    width: 100%;
}

.option {
    @include flex(space-between, flex-start, column);
    @include responsive(xs) {
        width: 100%;

        white-space: normal;
        text-align: center;
    }

    flex: 1;
    min-width: fit-content;

    p {
        @include responsive(xs) {
            align-self: center;
            margin-bottom: 1.2rem;
        }
        margin-bottom: 2.4rem;

        font-size: 2.4rem;

        font-family: $ff-text;
        line-height: 3.4rem;
        white-space: pre-wrap;
    }

    &:last-child {
        @include responsive(xs) {
            margin-bottom: 3rem;
            padding: 0;
        }

        padding-right: 5.1rem;
    }
}

.button {
    @include responsive(xs) {
        padding: 1.5rem 0;
    }
    @include responsive(md) {
        align-self: center;
        width: 100%;
        max-width: 20rem;
    }
}

.footerText {
    font-size: 1.1rem;
    font-family: $ff-text;
    line-height: 1.3rem;
    text-align: justify;
}
