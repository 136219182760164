@import 'scss/fonts';

@import 'scss/variables';

@import 'scss/mixins';

.container {
    display: inline-block;
    margin-top: 0.2rem;

    background-color: $most-popular-badge;
    border-radius: 0.4rem;
}

.text {
    @include responsive(xs) {
        font-size: 1rem;
    }
    padding: 0 min(0.6rem, 0.8vw);

    color: $black !important;

    font: $paragraph;
    font-size: min(1.2rem, 1.2vw);
    line-height: normal;
    vertical-align: middle;
}
