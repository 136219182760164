@use 'sass:color';

@import 'scss/variables';

@import 'scss/mixins';

@import 'scss/fonts';

.text {
    margin: 0;
    padding: 0;

    color: $blue;

    font: $body-desktop-pill-button !important;
    line-height: normal;

    transition: 0.2s;
}

.icon {
    filter: $svg-blue;
}

.container {
    @include flex(flex-end, flex-start, row);
    gap: 1rem;

    background: none;

    border: none;

    cursor: pointer;

    &:hover {
        .text {
            color: color.adjust($blue, $lightness: -10%);
        }

        .icon {
            fill: color.adjust($blue, $lightness: -10%);
        }
    }

    &:active {
        .text {
            color: color.adjust($blue, $lightness: -20%);

            transform: translateY(5%);
        }

        .icon {
            transform: translateY(5%);

            fill: color.adjust($blue, $lightness: -20%);
        }
    }
}
