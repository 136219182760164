@import 'scss/variables';

@import 'scss/fonts';

.text {
    margin: 0 !important;

    color: $background-footer !important;
    font: $body-desktop-xs !important;
}

.link {
    color: $blue !important;
    font: $body-desktop-xs !important;
    text-decoration: none;
}
