@import 'scss/variables';

@import 'scss/fonts';

@import 'scss/mixins';

.printerButtonGroup {
    @include flex(center, flex-start, column);
    gap: $sp-200;

    fieldset {
        @include flex(center, center, column);
        gap: $sp-200;
        max-width: 53.6rem;
        padding: 0;
    }
}

.linkContainer {
    @include font-size($fs-body-small);
    @include flex(center, center, row);
    @include responsive(md) {
        margin-inline: auto;
    }

    p {
        margin: 0;
        padding: 0;
    }
}

.link {
    margin: 0;
    margin-left: 0.5rem;
    padding: 0;

    color: $blue;

    background: none;
    border: none;
}
