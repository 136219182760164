@import 'scss/mixins';

@import 'scss/variables';

@import 'scss/fonts';

.container {
    @include flex(space-between, center, row);
    @include background-image(
        '/assets/theres_an_option_background.webp',
        cover,
        max(375px, 100%)
    );
    @include responsive(xs) {
        @include flex(center, flex-start, row);
        @include background-image(
            $url: '/assets/theres_an_option_background_mobile.webp',
            $position: bottom right
        );
        min-width: 35.7rem;
        height: 96vh;
        padding: 3.2rem 2rem;
    }

    min-width: 100vmin;
    min-height: 100vh;
    padding: 9rem 4rem;

    color: $white;

    white-space: pre-line;

    background-color: $black;
}

.left {
    @include flex(center, flex-start, column);
    gap: 3.2rem;
    width: 100%;
}

.title {
    @include responsive(md) {
        font-size: 5.5rem;
        line-height: 5.5rem;
    }
    @include responsive(xs) {
        font-size: 3rem;
        line-height: 3rem;
    }
    font: $title;
}

.content {
    @include responsive(md) {
        font-size: 1.5rem;
        line-height: 2.3rem;
    }
    @include responsive(xs) {
        width: 32ch;

        white-space: normal;
    }
    margin-bottom: 1rem;

    font: $paragraph;
}
