@use 'sass:color';

@import 'scss/mixins';

@import 'scss/fonts';

@import 'scss/variables';

.container {
    @include flex(space-between, flex-stretch, column);
    @include responsive(md) {
        height: 60rem;
    }
    @include responsive(xs) {
        height: 52.5rem;
    }
    width: 30.9rem;
    height: 52.5rem;

    min-height: max-content;
    overflow: hidden;

    background-repeat: no-repeat;
    background-position: bottom center;
}

.textWrapper {
    padding: 2rem 2.5rem;

    font-weight: 400;

    white-space: normal;

    text-align: left;

    h3 {
        margin-bottom: 2rem;

        font-size: 3rem;
        font-family: $ff-ui;
        line-height: 3.4rem;
    }

    p {
        font-size: 2rem;
        font-family: $ff-text;
        line-height: 2.4rem;
    }

    a {
        color: white;

        transition: 0.2s;

        &:hover {
            color: color.adjust($white, $lightness: -15%);
        }
    }
}

.first {
    background-color: #aea5f6;
}

.second {
    color: $white;

    background-color: #485af7;
}

.third {
    background-color: #c2deff;
}
