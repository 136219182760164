@import 'scss/variables';

@import 'scss/mixins';

@import 'scss/fonts';

.container {
    @include flex(center, flex-start, row);
    gap: 1rem;

    color: $white;
    white-space: pre-wrap;
}

.icon {
    @include responsive(xs) {
        filter: brightness(0%);
    }
    width: 3rem;
    height: 3rem;
}

.text {
    @include responsive(xs) {
        color: $black;
        white-space: initial;
    }
    font-size: 1.5rem;
    font-family: $ff-micro;
    line-height: normal;
}
