@use 'sass:color';

@import 'scss/mixins';

@import 'scss/variables';

@import 'scss/fonts';

.innerContainer {
    @include flex(center, stretch, column);
    @include responsive(xs) {
        display: block !important;
        min-height: unset;
        max-height: unset;

        padding: 3rem 0;
    }
    gap: 3rem;
    width: 100%;
    min-height: 40rem;
    max-height: 100%;
}

.columnContainer {
    @include flex(flex-start, flex-start, row);
    @include responsive(md) {
        gap: 1.5rem;
    }
    @include responsive(xs) {
        @include flex(flex-start, flex-start, column);
        gap: 1rem;
    }
    gap: 3rem;
}

.footerContainer {
    margin-top: 3rem;

    text-align: center;
}
