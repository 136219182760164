$background-footer: #212121;
$black: #000;
$blue: #027aae;
$blue-500: #4384ff;
$blue-600: #457ce6;
$purple: #5866f7;
$border-gray-light: #e2e2e2;
$border-gray: #c8c8c8;
$dark-gray: #737373;
$dark-white: #ccc;
$darker-gray: #adadad;
$placeholder-gray: #585858;
$eight-gray: #404040;
$fifth-gray: #b6bfc5; // Seems unused
$font-color-dark: #212121;
$fourth-gray: #93a1ac; // Seems unused
$gray: #f8f8f8;
$gray-90: #4e4e4e;
$gray-85: #464d50;
$light-blue: #00a1dd;
$light-white: #f0f4fa;
$most-popular-badge: #c5d699;
$ninth-gray: #767676;
$porcelain: #eaf0f0;
$second-gray: #6c7d8b; // Seems unused
$seventh-gray: #c4c4c4;
$sixth-gray: #eaeaec;
$table-border: #ebebeb;
$third-gray: #f8fafb;
$transparent-black: rgba($black, 0.7);
$transparent-button-black: rgba($eight-gray, 0.5);
$transparent-gray: rgba($background-footer, 0.1);
$transparent-ligth-blue: rgba($light-blue, 0.1);
$white: #fff;
$red: #be1213;
$red-100: #e24a4a;
$green: #76cb76;
$svg-blue: invert(0.5) sepia(1) saturate(3) hue-rotate(160deg) brightness(0.8);

// Spacing

$sp-100: 0.8rem;
$sp-200: 1.6rem;
$sp-300: 2.4rem;
$sp-400: 3.2rem;
$sp-500: 4rem;
$sp-600: 4.8rem;
$sp-700: 5.6rem;
$sp-800: 6.4rem;
$sp-900: 7.2rem;

// Font weight

$fw-light: 300;
$fw-regular: 400;
$fw-bold: 700;
