@import 'scss/mixins';

@import 'scss/variables';

.container {
    @include responsive(xs) {
        width: 100%;
        height: 1px;

        border-right: none;
        border-bottom: 1px solid $black;
    }
    width: 1px;

    height: 45rem;

    border-right: 1px solid $black;
}
