@import 'scss/variables';

@import 'scss/mixins';

@import 'scss/fonts';

.container {
    @include responsive(md) {
        padding: 2.4rem 12.8rem;

        white-space: normal;
    }
    @include responsive(sm) {
        padding: 2.4rem 2.5rem;

        white-space: normal;
    }
    @include responsive(xs) {
        padding: 2.4rem 1.6rem;

        white-space: normal;
    }

    padding: 6.4rem 12.8rem;

    color: $white;
    font-weight: 400;
    font-family: $ff-micro;
    white-space: pre-wrap;
    text-align: center;

    background-color: $blue-500;
}

.printer {
    @include responsive(xs) {
        width: 100%;
        height: auto;
    }

    width: 62.2rem;
    height: auto;
}

.title {
    @include responsive(xs) {
        font-size: 3rem;
    }
    font-size: 4.8rem;
    font-family: $ff-micro;
}

.subtitle {
    @include responsive(xs) {
        font-size: 1.8rem;
    }

    font-weight: normal;
    font-size: 2.8rem;
}

.icons {
    @include flex(space-between, flex-start, row);
    @include responsive(xs) {
        @include flex(flex-start, flex-start, column);
    }
    margin: 0;
    padding: 0;
}

.footer {
    @include flex(center, center, column);

    gap: 3.2rem;
    margin-top: 6.4rem;
}

.button {
    font-size: 1.6rem;
    font-family: $ff-text;
}

.footnote {
    @include responsive(xs) {
        font-size: 1.2rem;
    }

    font-size: 1.4rem;
}
