@import 'scss/fonts';

.container {
    text-align: left;
}

.text {
    margin: 0;
    padding: 0;
    overflow: hidden;

    font: $paragraph-greek;
    line-height: 2rem;
    white-space: pre;
    text-overflow: ellipsis;
}
