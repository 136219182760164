@import 'scss/fonts';

@import 'scss/variables';

@import 'scss/mixins';

.customModalContainer {
    @include responsive(md) {
        height: 70rem;
    }
    @include responsive(xs) {
        width: 100%;
        height: 20rem;
        margin-bottom: 3rem;
    }
    width: 50%;
    height: 100%;
    object-fit: cover;
}

.modalTitle {
    font: $title;
    font-size: clamp(3rem, 2vw, 5rem);
    line-height: normal;
}

.modalText {
    margin-top: 3rem;

    font: $paragraph;
    font-size: clamp(1.6rem, 1vw, 2.3rem);
    line-height: normal;
}
