@import 'scss/mixins';

@import 'scss/fonts';

@import 'scss/variables';

.headerContainer {
    @include flex(center, center, column);
    padding-top: 8.4rem;

    color: $white;
    font-weight: 400;
    font-family: $ff-micro;
    white-space: pre-wrap;

    text-align: center;

    background-color: #465fe5;

    h1 {
        @include responsive(xs) {
            font-size: calc(8.4rem / 2);
        }
        font-size: 8.4rem;
    }

    img {
        margin-top: 6.4rem;
    }
}

.mainContainer {
    @include responsive(md) {
        padding: 0 1rem;
    }
    margin-top: 4.1rem;

    font-family: $ff-text;
    text-align: center;

    p {
        @include responsive(xs) {
            font-size: 1.4rem;
        }
        max-width: 75ch;
        margin: 0 auto;

        font-size: 2rem;
        font-family: $ff-micro;
    }

    h2 {
        @include responsive(xs) {
            font-size: 2rem;
        }

        margin-top: 2rem;

        font-weight: 500;
        font-size: 4rem;
    }
}

.blockContainer {
    @include flex(center, center, row);
    @include responsive(xs) {
        @include flex(center, center, column);
    }

    gap: 2rem;
    margin-top: 4rem;
    margin-bottom: 7.5rem;
}

.ctaContainer {
    font-family: $ff-text;

    h3 {
        font-size: 3.4rem;
    }

    ul {
        @include flex(center, center, row);

        gap: 4.2rem;
        margin: 0;
        margin-top: 2.1rem;
        margin-bottom: 5.5rem;
        padding: 0;

        font-size: 1.6rem;
    }

    .ctaSubtitle {
        margin-top: 1.1rem;

        font-weight: 400;
        font-size: 1.6rem;
        font-family: $ff-text;
        text-align: center;
    }
}

.button {
    padding: 1.5rem 3.55rem;

    font-size: 2.4rem;
}

.check {
    width: 2.2rem;
    height: 2.2rem;
    margin-right: 1rem;

    background-color: #485af7;
    border-radius: 50%;
}

.footerContainer {
    @include flex(center, center, row);
    @include responsive(md) {
        margin-top: 13.15rem;
    }
    @include responsive(xs) {
        @include flex(center, center, column);
        margin: 0;
    }
    gap: 1.4rem;
    margin-top: 26.3rem;
    margin-bottom: 2.6rem;

    font-size: 2rem;
    font-family: $ff-text;

    button {
        margin: 0;
        padding: 0;

        color: #5866f7;

        background: none;
        border: none;
        cursor: pointer;
    }
}

.faqs {
    @include flex(center, flex-start, column);
    @include responsive(xs) {
        @include flex(center, flex-start, column);
        gap: 3rem;
        min-width: 35.7rem;
        min-height: 120vmin;
        padding: 3.2rem 2rem;
    }
    gap: 4.7rem;

    min-width: 100vmin;

    padding: 13.1rem 4rem 8.1rem;

    color: $black;

    white-space: pre-line;

    background-color: $gray;

    h3 {
        font-size: 6.4rem;
        font-family: $ff-display;
        line-height: 6.4rem;
    }
}

.accordionContainer {
    @include flex(center, stretch, column);
    gap: 1.2rem;
    width: 100%;
}

.disclaimers {
    margin: 5.7rem 3.2rem;

    font-family: $ff-text;

    h3 {
        font-size: 6.4rem;
        line-height: 6.4rem;
    }

    ul {
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2.4rem;

        list-style: decimal;
    }

    li {
        margin-top: 1.6rem;
    }
}
