@import 'scss/mixins';

@import 'scss/fonts';

@import 'scss/variables';

.container {
    position: fixed;
    z-index: 999;

    width: 100vw;
}

.testButton {
    position: absolute;
    top: 10px;

    color: $white;

    border-color: $white;
}

.testModal {
    @include flex(center, stretch, column);
    gap: 3rem;
}
