@import 'scss/mixins';

@import 'scss/variables';

@import 'scss/fonts';

.container {
    padding: 0 20%;

    color: $ninth-gray;
    font: $h5-desktop-m !important;

    border-top: 1px solid $seventh-gray;

    a {
        color: $blue;
        font: $h5-desktop-m !important;
        text-decoration: none !important;
    }

    p {
        margin: 0;
    }

    b {
        font-weight: normal;
    }

    li {
        margin: 1rem 0;
    }
}

.termsContent {
    list-style: upper-roman;

    & > li > div > ol {
        list-style: upper-latin;

        & > li > ol {
            list-style: decimal;

            & > li > ol {
                list-style: lower-latin;

                & > li > ol {
                    list-style: lower-roman;
                }
            }
        }
    }
}

.print {
    .container {
        padding: 0;

        border-top: none;
    }
}
