@import 'scss/fonts';

@import 'scss/variables';

@import 'scss/mixins';

.image {
    width: min(50rem, 45vw);
    height: min(50rem, 45vw);

    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.imageSmall {
    max-width: 38.8rem;
    max-height: 28.3rem;
}

.container {
    @include flex(space-around, center, row);
    @include responsive(xs) {
        @include flex(center, center, column);
    }

    min-height: 65vmin;
    overflow: hidden;

    &:last-child {
        .image {
            width: min(50rem, 50vw);
            height: min(50rem, 50vw);

            background-position: center 60%;
        }
    }
}

.rightSectionContainer {
    @include flex(center, center, column);
    @include responsive(xs) {
        @include flex(center, stretch, column);
        width: 100%;
        margin-bottom: 5rem;
    }

    width: unset;
}

.buttonContainer {
    @include flex(center, flex-start, column);
    @include responsive(xs) {
        @include flex(center, center, column);
        gap: 1.1rem;
        margin: 4.1rem 0 1.9rem;
    }
    gap: 1.35rem;
    margin: 2.8rem 0 1.9rem;
}

.title {
    @include responsive(xs) {
        font-size: 3rem;
        text-align: center;
    }
    width: 100%;

    font: $paragraph;
    font-size: min(3.8rem, 3.5vw);
    line-height: 4rem;
    letter-spacing: 0.32px;
    white-space: pre-wrap;
}
