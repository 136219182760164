@import 'scss/variables';

@import 'scss/mixins';

@import 'scss/fonts';

.container {
    @include flex(space-between, center, row);
    @include responsive(xs) {
        @include flex(space-between, center, column);
        width: 100%;
        padding: 2rem;
    }
    position: fixed;
    right: 0;
    bottom: 0;

    z-index: 8;

    width: 55rem;
    padding: 2rem 4rem;

    color: $background-footer;

    background-color: $porcelain;
}

.leftContainer {
    @include responsive(xs) {
        @include flex(space-between, center, row);
        gap: 1rem;
    }

    h1 {
        @include responsive(xs) {
            font: $h4-desktop-l;
        }
        color: $blue;
        font: $h4-desktop-l;
    }

    p {
        margin: 0;
        padding: 0;

        font: $caption-desktop-xs;
    }
}
