@import 'scss/mixins';

@import 'scss/variables';

@import 'scss/fonts';

.card {
    @include responsive(xs) {
        min-width: 25.5rem;
    }
    position: relative;

    width: 50%;
    height: 100%;
}

.printerImage {
    position: absolute;
    top: -4.5rem;
    left: 50%;

    height: 12rem;

    transform: translateX(-50%);

    pointer-events: none;
}

.separator {
    width: 100%;

    border: none;
    border-top: 1px solid $border-gray;
}

.container {
    @include flex(flex-start, center, column);
    @include responsive(xs) {
        min-width: auto;
        min-height: 60rem;
    }
    height: 100%;

    min-height: 50rem;
    padding: 6rem 1rem 2rem;

    color: $black;

    font-family: $ff-ui;

    white-space: pre-wrap;

    border: 1px solid $border-gray;
    border-radius: 1.2rem;
}

.active {
    color: $white;

    background-color: $blue;
    border: none;

    & .separator {
        border-top: 1px solid $white;
    }
}

.title {
    text-align: center;
}

.list {
    @include flex(flex-start, flex-start, column);
    gap: 1rem;
    width: 90%;
    margin: 0;
    padding: 0;

    white-space: normal;

    li {
        line-height: normal;

        list-style: none;

        &::before {
            font-size: 2.5rem;
            vertical-align: middle;

            content: '· ';
        }
    }
}
