@use 'sass:math';

$available-breakpoints: (
    xs: 767px,
    sm: 1023px,
    md: 1439px,
    lg: 1919px,
);

@mixin responsive($breakpoint) {
    @media screen and (max-width: map-get($available-breakpoints, $breakpoint)) {
        @content;
    }
}

@mixin flex($justify: center, $align: center, $direction: row) {
    display: flex !important;
    flex-direction: $direction !important;
    align-items: $align !important;
    justify-content: $justify !important;
}

@mixin background-image(
    $url,
    $size: contain,
    $position: center,
    $repeat: no-repeat
) {
    background-image: url($url);
    background-repeat: $repeat;
    background-position: $position;
    background-size: $size;
}

@mixin font-size($fs-map) {
    @each $fs-key, $fs-value in $fs-map {
        @if $fs-key == lg {
            font-size: $fs-value;
            line-height: normal;
        } @else {
            @include responsive($fs-key) {
                font-size: $fs-value;
                line-height: normal;
            }
        }
    }
}
