.body {
    li {
        margin: 1rem 0;
    }

    ol {
        list-style: decimal;

        & > li > ol {
            list-style: upper-latin;
        }
    }
}
