@import 'scss/mixins';

@import 'scss/fonts';

@import 'scss/variables';

.container {
    @include flex(center, center, column);
    @include responsive(md) {
        padding: 2rem 0;

        font: $paragraph-greek;
    }
    min-height: 10rem;

    color: $white;
    font: $footer-desktop;
    text-align: center;

    background: $background-footer;
}

.linkContainer {
    @include flex(center, center, row);
    @include responsive(xs) {
        display: block !important;
        width: 35ch;
        padding: 1.5rem 0;
    }
    gap: 1rem;

    overflow: hidden;
}

.copyrightText {
    @include responsive(xs) {
        font: $paragraph-greek;
        font-size: 1rem;
    }
    margin-top: 0.4rem;

    font: $paragraph-greek;
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: 0.02rem;
}
