@import 'scss/mixins';

.navigationBar {
    @include flex(space-between, flex-end, row);
    @include responsive(xs) {
        padding: 1.6rem 2.4rem;
    }
    padding: 3.2rem;

    & > button {
        padding: 0;

        background: none;
        border: none;
    }
}

.float {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
}
