@import 'scss/variables';

@import 'scss/fonts';

@import 'scss/mixins';

.container {
    @include responsive(md) {
        padding: 2.4rem 12.8rem;

        white-space: normal;
    }
    @include responsive(sm) {
        padding: 2.4rem 2.5rem;

        white-space: normal;
    }
    @include responsive(xs) {
        padding: 2.4rem 1.6rem;

        white-space: normal;
    }

    padding: 6.4rem 12.8rem;

    font-weight: 400;
    font-family: $ff-ui;
    text-align: center;
}

.title {
    @include responsive(xs) {
        font-size: 3rem;
    }
    margin-bottom: 6.4rem;

    font-weight: 400;
    font-size: 6.4rem;
    font-family: $ff-micro;

    text-align: center;
}

.itemsContainer {
    @include flex(space-between, flex-start, row);
    @include responsive(xs) {
        @include flex(flex-start, flex-start, column);
        gap: 2.4rem;
    }
    gap: 1.6rem;
    margin: 0 0 4.8rem;
    padding: 0;
}

.button {
    font-size: 1.6rem;
    font-family: $ff-text;
}
