@import 'scss/fonts';

@import 'scss/variables';

@import 'scss/mixins';

.container {
    @include flex(flex-start, flex-start, column);
    @include responsive(md) {
        width: 50%;

        padding: $sp-400;
    }
    @include responsive(xs) {
        width: 100%;
        padding: $sp-200 $sp-400 0;
    }

    width: 37.6rem;

    margin: $sp-200 0;
    padding: $sp-300 $sp-400;

    text-align: left;

    & > h3 {
        @include font-size($fs-h4);
        margin-top: $sp-300;
        margin-bottom: $sp-100;
    }

    & > p {
        @include font-size($fs-body);
    }

    &:not(:first-child) {
        @include responsive(md) {
            border: none;
        }

        border-left: 1px solid $black;
    }
}
