@import 'scss/mixins';

@import 'scss/variables';

@import 'scss/fonts';

.containerFaqs {
    h4 {
        @include responsive(md) {
            font: $faqs-h4-xs;
        }
        font: $faqs-h4-desktop;
    }

    h5 {
        @include responsive(md) {
            font: $faqs-h5-xs;
        }
        font: $faqs-h5-desktop;
    }
}
