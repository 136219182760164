@import 'scss/fonts';

@import 'scss/variables';

@import 'scss/mixins';

.textConfig {
    @include responsive(xs) {
        padding: 0 1rem;

        white-space: normal;
    }

    font-family: $ff-ui;
    white-space: pre-wrap;
    text-align: center;

    h1,
    h2,
    p,
    button {
        font-weight: normal;
    }
}

.header {
    position: relative;

    padding: 5.2rem 0;

    background-color: $purple;

    h1 {
        @include font-size($fs-main-title);
        color: $white;
    }

    img {
        @include responsive(xs) {
            width: 90%;
        }

        position: relative;
        z-index: 3;

        width: 45.7rem;
        height: auto;

        filter: drop-shadow(0 10px 5px rgb(0 0 0 / 40%));
    }

    hr {
        position: absolute;
        bottom: 8.5rem;
        left: 0;

        width: 100%;

        height: 10rem;

        background: linear-gradient(0deg, #5765f6, #3440e7);

        border: none;
    }
}

.main {
    margin-top: 5.2rem;

    h2 {
        @include font-size($fs-h2);
        margin-bottom: 3.2rem;
    }

    p {
        @include font-size($fs-body);
        margin-bottom: 5.2rem;
    }
}

.footer {
    @include responsive(xs) {
        margin: 10rem 0 3rem;
    }

    margin: 13rem 0 10rem;

    p {
        @include font-size($fs-body);
    }

    ul {
        @include flex(center, center, row);
        gap: 1rem;
    }

    .separator {
        font-weight: bold;
        font-size: 1.4rem;
    }

    button {
        @include font-size($fs-footnote);
        padding: 0;

        color: #5866f7;
        text-decoration: none;

        background: none;
        border: none;
    }
}
